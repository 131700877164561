<script lang="ts">
    import { onMount } from 'svelte';
    import { Route, Router, navigate } from "svelte-routing";
    import MiddleSection from './lib/MiddleSection.svelte';
    import Login from './lib/Login.svelte';
    import TopSection from "./lib/TopSection.svelte";
    import { loggedIn } from "./lib/services/logoutHelper";
    import CreateUser from "./lib/CreateUser.svelte";
    import ForgotPassword from "./lib/ForgotPassword.svelte";
    import ChangePassword from "./lib/ChangePassword.svelte";
    import ValidationPage from "./lib/ValidationPage.svelte";
    import ConfirmationPending from "./lib/ConfirmationPending.svelte";

    let loading = true;

    async function checkAuthStatus() {
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/auth/verify`, {
                method: 'GET',
                credentials: 'include'
            });

            if (window.location.pathname.startsWith('/reset-password')) {
                return;
            }

            if (response.ok) {
                loggedIn.set(true);
                if (window.location.pathname === '/login') {
                    navigate('/');
                }
            } else {
                loggedIn.set(false);
                if (window.location.pathname !== '/login') {
                    navigate('/login');
                }
            }
        } catch (error) {
            console.error('Auth check failed:', error);
            loggedIn.set(false);
            navigate('/login');
        } finally {
            loading = false;
        }
    }

    onMount(() => {
        const isValidationRoute = window.location.pathname.includes('/validate')
        if (!isValidationRoute) {
            checkAuthStatus();
        }
        else {
            loading = false;
        }
    });
</script>

{#if loading}
    <div class="loading-container">
        <div class="loading-spinner"></div>
    </div>
{:else}
    {#if $loggedIn}
        <TopSection />
        <MiddleSection />
    {:else}
        <Router>
            <Route path="/login" component={Login} />
            <Route path="/" component={Login} />
            <!--<Route path="/signup" component={CreateUser} /> --> <!--COMMENTED OUT FOR DEPLOYMENT-->
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password/:token" component={ChangePassword} />
            <Route path="/validate/:token" component={ValidationPage} />
            <Route path="/confirmation-pending" component={ConfirmationPending} />
        </Router>
    {/if}
{/if}

<style>
    .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-color: #f5f5f5;
    }

    .loading-spinner {
        width: 40px;
        height: 40px;
        border: 4px solid #f3f3f3;
        border-top: 4px solid #3498db;
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>