<script lang="ts">
    import {
        type Category
    } from "../store";
    import EntryFormCategorySearchNewWindowCategoryElement from "./EntryFormCategorySearchNewWindowCategoryElement.svelte";
    import {createEventDispatcher} from "svelte";
    import InputField from "./InputField.svelte";

    export let category: Category;
    export let categories: Map<string, Category>;

    const dispatch = createEventDispatcher();

    let isAdding = false;

    function getCategory(child: string) {
        return categories.get(child) as Category;
    }

    function handleAddNewInputSubmit(event: CustomEvent) {
        isAdding = false;
        dispatch('newValue', {name: event.detail.value, parent: category._id})
    }

    function handleAddNewInputCancel() {
        isAdding = false;
    }

</script>

<ul class="list">
    <li>
        <div class="row">
            <div class="element">
                <button class="dropdown-item rounded"
                        on:click|stopPropagation={() => {
                        dispatch('select', {parent: category._id})
                    }}>
                    {category.name}
                </button>
                <button class="add-button" on:click|stopPropagation={() => {isAdding = true}}>
                    <svg width="16" height="16">
                        <use href="#icon-add"/>
                    </svg>
                </button>
            </div>
            {#if isAdding}
                <div class="addNewFieldSection">
                    <InputField theme="dark" placeholder="Add New Category" on:submit={handleAddNewInputSubmit} on:cancel={handleAddNewInputCancel}/>
                </div>
            {/if}
        </div>
        {#if category.children.length > 0}
            <ul class="sublist">
                {#each category.children as child}
                    <EntryFormCategorySearchNewWindowCategoryElement on:select={(event) => dispatch('select', {parent: event.detail.parent})}
                                                                     on:newValue={(event) => dispatch('newValue', {name: event.detail.name, parent: event.detail.parent})}
                                                                     category={getCategory(child)} categories={categories} />
                {/each}
            </ul>
        {/if}
    </li>
</ul>

<style>
    .addNewFieldSection {
        margin-left: 1.5rem;
    }

    .row {
        display: flex;
        flex-direction: column;
    }

    .list {
        list-style: none;
        margin: 0 0 0 1rem;
        padding: 0;
    }

    .sublist {
        margin: 0;
        padding: 0;
    }

    .dropdown-item {
        all: unset;
        cursor: pointer;
        padding: 0.3rem 0.75rem;
    }

    .dropdown-item:disabled{
        color: grey;
        cursor: unset;
    }

    .add-button {
        all: unset;
        visibility: hidden;
        margin-left: 0.5rem;
    }

    .element {
        display: flex;
        align-items: center;
    }

    .element:hover .add-button {
        visibility: visible;
    }

    .dropdown-item:hover {
        background-color: #87888f;
    }
</style>