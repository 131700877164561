<script lang="ts">
    import EntryGrid from "./EntryGrid.svelte";
    import TimeBar from "./TimeBar.svelte";
    import Filters from "./Filters.svelte";
    import CategorySidebar from "./CategorySidebar.svelte";
    import CustomCategorySidebar from "./CustomCategorySidebar.svelte";
    import { onMount } from "svelte";
    import {
        showEntryForm,
        categories,
        type Category,
        categoryTree, type CustomCategoryElement, customCategoryElements, type CustomCategoryName, customCategoryNames,
        type Status,
        statuses,
        type Tag,
        tags,
        uiTopSectionHeight, selectedCustomCategoryBarIds
    } from "../store";
    import { buildCategoryTree } from "./services/buildCategoryTreeService";
    import {buildCustomCategories} from "./services/buildCustomCategoriesService";

    let timebar: HTMLElement;
    let catSide: HTMLElement;
    let customCatSide: HTMLElement;
    let filters: HTMLElement;

    let entryGridHeight = '0vh';
    let entryGridWidth = '0vw';
    let catBarHeight = '0vh';

    let catSideWidth = 20;
    let customCatSideWidth = ((330 / window.innerWidth) * 100);

    const updateHeightWidth = () => {
        const windowHeight = window.innerHeight;

        if (windowHeight && timebar && filters) {
            const topSectionHeightPercent = ($uiTopSectionHeight / window.innerHeight) * 100;
            const timebarHeightPercent = (timebar.clientHeight / window.innerHeight) * 100;
            const filtersHeightPercent = ((filters.clientHeight + 1) / window.innerHeight) * 100;
            entryGridHeight = `${100 - topSectionHeightPercent - timebarHeightPercent - filtersHeightPercent}vh`;
            catBarHeight = `${100 -topSectionHeightPercent}vh`;
        }

        entryGridWidth = `calc(100vw - ${catSideWidth}vw - ${customCatSideWidth}vw)`;
    };

    function startResizingCatSide(event: MouseEvent) {
        event.preventDefault();

        function resize(event: MouseEvent) {
            const viewportWidth = window.innerWidth;
            catSideWidth = (event.clientX / viewportWidth) * 100;
            catSideWidth = Math.max(10, Math.min(catSideWidth, 40));
            updateHeightWidth();
        }

        function stopResizing() {
            window.removeEventListener("mousemove", resize);
            window.removeEventListener("mouseup", stopResizing);
        }

        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResizing);
    }

    function startResizingCustomCatSide(event: MouseEvent) {
        event.preventDefault();

        function resize(event: MouseEvent) {
            const viewportWidth = window.innerWidth;
            customCatSideWidth = ((viewportWidth - event.clientX) / viewportWidth) * 100;
            //customCatSideWidth = Math.max(10, Math.min(customCatSideWidth, 40));
            customCatSideWidth = Math.max(((330 / window.innerWidth) * 100), Math.min(customCatSideWidth, 40));
            updateHeightWidth();
        }

        function stopResizing() {
            window.removeEventListener("mousemove", resize);
            window.removeEventListener("mouseup", stopResizing);
        }

        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResizing);
    }

    async function fetchEverythingForStore() {
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/all`)
            const data = await response.json();

            categories.set(new Map(data.categories.map((cat: Category) => [
                cat._id,
                {
                    _id: cat._id,
                    name: cat.name,
                    parentId: cat.parentId,
                    children: []
                }
            ])));

            tags.set(new Map(data.tags.map((tag: Tag) => [
                tag._id,
                {
                    _id: tag._id,
                    name: tag.name
                }
            ])));

            statuses.set(new Map(data.statuses.map((status: Status) => [
                status._id,
                {
                    _id: status._id,
                    name: status.name
                }
            ])));

            customCategoryNames.set(new Map(data.customcategorynames.map((customCategoryName: CustomCategoryName) => [
                customCategoryName._id,
                {
                    _id: customCategoryName._id,
                    name: customCategoryName.name
                }
            ])));

            customCategoryElements.set(new Map(data.customcategoryelements.map((customCategoryElement: CustomCategoryElement) => [
                customCategoryElement._id,
                {
                    _id: customCategoryElement._id,
                    name: customCategoryElement.name,
                    parentId: customCategoryElement.parentId
                }
            ])));

            categoryTree.set(buildCategoryTree($categories));
            buildCustomCategories();

            if ($customCategoryNames.size === 1) {
                selectedCustomCategoryBarIds.set([[...$customCategoryNames.keys()][0], ''])
            } else if ($customCategoryNames.size > 1) {
                selectedCustomCategoryBarIds.set([[...$customCategoryNames.keys()][0], [...$customCategoryNames.keys()][1]]);
            }
        } catch (error) {
            console.error(error);
        }
    }

    onMount(() => {
        updateHeightWidth();

        window.addEventListener('resize', updateHeightWidth);

        const resizeObserver = new ResizeObserver(() => {
            updateHeightWidth();
        });
        resizeObserver.observe(catSide);
        resizeObserver.observe(customCatSide);
        resizeObserver.observe(filters);

        fetchEverythingForStore()

        return () => {
            window.removeEventListener('resize', updateHeightWidth);
            resizeObserver.disconnect();
        };
    });
</script>

<div class="d-flex vw-100 bg-dark">
    <div class="bar d-flex" bind:this={catSide} style={`height: ${catBarHeight}; width: ${catSideWidth}vw;`}>
        <CategorySidebar />
        <button class="resize-handle" on:mousedown={startResizingCatSide}></button>
    </div>

    <div class="mid d-flex flex-column">
        <div class="filters" bind:this={filters}>
            <Filters />
        </div>
        <div class="d-flex flex-column">
            <div class="entries" style={`height: ${entryGridHeight}; width: ${entryGridWidth};`}>
                <EntryGrid />
                <!-- Add Button in Bottom Right Corner of Entry Grid -->
                <div class="button-container">
                    <button class="add-button rounded-circle btn btn-primary" on:click={() => showEntryForm.set(true)} data-testid="add-note-button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 64 64">
                            <line x1="32" y1="10" x2="32" y2="54" stroke="white" stroke-width="4"/>
                            <line x1="10" y1="32" x2="54" y2="32" stroke="white" stroke-width="4"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="timebar" bind:this={timebar}>
                <TimeBar />
            </div>
        </div>
    </div>

    <div class="custom-bar d-flex bg-dark" bind:this={customCatSide} style={`height: ${catBarHeight}; width: ${customCatSideWidth}vw;`}>
        <CustomCategorySidebar />
        <button class="resize-handle" on:mousedown={startResizingCustomCatSide}></button>
    </div>
</div>

<style>
    .bar, .custom-bar {
        flex-shrink: 0;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .bar {
        padding-right: 5px;
    }

    .mid {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .filters {
        flex-shrink: 0;
    }

    .entries {
        position: relative;
        overflow: auto;
        background-color: #2c313a;
    }

    .button-container {
        position: sticky;
        bottom: 1.5rem;
        right: 1.5rem; /* Move to the rightmost corner */
        display: flex;
        justify-content: flex-end; /* Ensures button stays on the right */
        z-index: 100;
        margin-right: 2rem;

    }

    .add-button {
        width: 3rem;
        height: 3rem;
        align-self: flex-end; /* Align the button to the rightmost side */
    }

    .timebar {
        height: 12vh;
        flex-shrink: 0;
        background-color: #343a40;
    }

    .resize-handle {
        all: unset;
        width: 5px;
        cursor: ew-resize;
        background-color: rgba(255, 255, 255, 0.2);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
    }

    .custom-bar .resize-handle {
        left: 0;
    }

    .resize-handle:hover {
        background-color: rgba(255, 255, 255, 0.4);
    }
</style>
