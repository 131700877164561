<script lang="ts">
    import {
        type CustomCategoryElement,
        selectedCustomCategories
    } from "../store";
    import {createEventDispatcher} from "svelte";
    import InputField from "./InputField.svelte";

    export let parentId: string;
    export let customCategoryElements: CustomCategoryElement[];

    const dispatch = createEventDispatcher();

    let editElementId = '';
    let newElementName = '';

    function editCustomElement(elementId: string, name: string) {
        if (editElementId === '') {
            editElementId = elementId;
            newElementName = name;
        }
    }

    function toggleSelectElement(elementId: string) {
        selectedCustomCategories.update(current => {
            const pair = [elementId, parentId];
            if (current.some(([selectedId, selectedParent]) => selectedId === elementId && selectedParent === parentId)) {
                return current.filter(([selectedId, selectedParent]) => selectedId !== elementId || selectedParent !== parentId);
            } else {
                return [...current, pair];
            }
        });
    }

    function handleEditCategoryNameInputSubmit(event: CustomEvent, id: string, parentId: string, oldName: string) {
        dispatch('edit', {elementId: id, parentId: parentId, newName: event.detail.value, oldName: oldName})
        newElementName = '';
        editElementId = '';
    }

    function handleEditCategoryNameInputCancel() {
        newElementName = '';
        editElementId = '';
    }
</script>

<div class="comp">
    <ul class="list-unstyled">
        {#each customCategoryElements as element}
            <li class="list-item text-white rounded">
                <div class="element rounded" class:selected={$selectedCustomCategories.some(([selectedId, selectedParent]) =>
    selectedId === element._id && selectedParent === parentId
)}>
                    {#if editElementId === element._id}
                        <div class="editFieldSection">
                            <InputField inputValue={newElementName} on:submit={(event) => handleEditCategoryNameInputSubmit(event, element._id, element.parentId, element.name)} on:cancel={handleEditCategoryNameInputCancel}/>
                        </div>
                    {:else}
                        <div class="element d-flex rounded">
                            <button class="textelement" on:click={() => toggleSelectElement(element._id)}>
                                <span class="text">
                                    {element.name}
                                </span>
                            </button>
                            <div class="action-buttons d-flex justify-content-between">
                                <button class="category_buttons" on:click|stopPropagation={() => editCustomElement(element._id, element.name)}>
                                    <svg width="16" height="16">
                                        <use href="#icon-edit-simple"/>
                                    </svg>
                                </button>
                                <button class="category_buttons" on:click|stopPropagation={() => dispatch('delete', {elementId: element._id, parentId: element.parentId})}>
                                    <svg width="16" height="16">
                                        <use href="#icon-trash"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    {/if}
                </div>
            </li>
        {/each}
    </ul>
</div>

<style>
    .editFieldSection {
        padding-top: 0.187rem;
        padding-bottom: 0.187rem;
        margin-left: 0.3rem;
    }

    .comp {
        overflow: auto;
        margin-left: 0.5rem;
        margin-top: 0.5rem;
    }

    .list-item {
        cursor: pointer;
        background-color: #212529;
        width: 100%;
        box-sizing: border-box;
        text-align: left;
    }

    .selected {
        background-color: #292f38;
    }
    .action-buttons {
        display: inline-flex;
        margin-left: auto; /* Push icons to the right side */
    }

    .action-buttons button {
        display: inline-block;
        background: none;
        border: none;
        color: #fff; /* Icon color */
        font-size: 16px;
        margin-right: 8px;
        cursor: pointer;
    }

    .action-buttons button:hover {
        color: #fff; /* Highlight icon on hover */
    }

    /* Hide action buttons by default */
    .action-buttons {
        visibility: hidden;
    }

    /* Show buttons on hover */
    .element:hover .action-buttons {
        visibility: visible;
    }

    .list-item .element {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .textelement {
        all: unset;
        padding: 0.375rem 1.5rem 0.375rem 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis
    }

    .list-item:hover {
        background-color: #1c2026;
    }

    .text {
        flex: 1; /* Make the text span available space */
        overflow: hidden; /* Enable text overflow handling */
        white-space: nowrap; /* Prevent text wrapping to a new line */
        text-overflow: ellipsis; /* Add ellipsis for overflowing text */
    }

    .element {
        overflow: hidden; /* Enables text overflow handling */
        text-align: left; /* Align the text to the left */
    }
</style>