<script lang="ts">
    import {selectedCategories, categories, categoryTree, type Category} from "../store";
    import CategoryTree from "./CategoryTree.svelte";
    import {addCategory} from "./services/addCategoryService";
    import {fetchCategories} from "./services/fetchCategoriesService";
    import {buildCategoryTree} from "./services/buildCategoryTreeService";
    import InputField from "./InputField.svelte";

    async function handleFetchCategories() {
        await fetchCategories();
        categoryTree.set(buildCategoryTree($categories));
    }

    let isAdding = false;

    async function handleAddCategory(newCategoryName:string) {
        await addCategory(newCategoryName, '');
        await handleFetchCategories()
    }

    function handleInputSubmit(event: CustomEvent) {
        isAdding = false;
        handleAddCategory(event.detail.value);
    }

    function handleInputCancel() {
        isAdding = false;
    }

    function openTextField() {
        isAdding = true;
    }

    $: if ($categories) {
        categoryTree.set(buildCategoryTree($categories));
    }
</script>

<div class="comp bg-dark">
    <div class="sidebar bg-dark text-white d-flex flex-column">
        <div class="main h3 mb-2 d-flex justify-content-between">
            Category
            <div class="action-buttons">
                <button on:click|stopPropagation={openTextField}>
                    <svg width="15" height="15">
                        <use href="#icon-add"/>
                    </svg>
                </button>
            </div>
        </div>

        {#if isAdding}
            <div class="addNewFieldSection">
                <InputField placeholder="Add New Category" on:submit={handleInputSubmit} on:cancel={handleInputCancel}/>
            </div>
        {/if}

        {#each $categoryTree as rootCategory}
            <CategoryTree category={rootCategory} />
        {/each}
    </div>
</div>


<style>
    .addNewFieldSection {
        padding-left: 1.5rem;
    }

    .comp {
        overflow: auto;
    }

    .main {
        padding-left: 1rem;
    }

    .sidebar {
        padding-top: 1rem;
        padding-right: 0;
    }

    .action-buttons button {
        display: inline-block;
        background: none;
        border: none;
        color: #fff; /* Icon color */
        font-size: 16px;
        margin-right: 8px;
        cursor: pointer;
    }
</style>
