<script lang="ts">
    import {
        categories,
        entries,
        selectedEntry,
        entryFormSelectedTagsIds,
        entryFormSelectedStatusesIds,
        entryFormSelectedCategoriesIds,
        rowsCustomCat,
        isEditingModal,
        type Entry
    } from "../store";
    import {showModal} from "../store";
    import {fetchNotes} from "./services/fetchNoteService";
    import {onDestroy, onMount} from "svelte";
    import ModalEditingView from "./ModalEditingView.svelte";
    import ModalDefaultView from "./ModalDefaultView.svelte";
    import BookmarkEditingView from "./BookmarkEditingView.svelte";
    import BookmarkDefaultView from "./BookmarkDefaultView.svelte";
    import DeleteConfirmation from "./DeleteConfirmation.svelte";

    let isNote = $selectedEntry.contentType==="Note";
    let url = $selectedEntry.url;
    let title = $selectedEntry.title;
    let value = $selectedEntry.value;
    let container: HTMLElement;
    let selected: Entry;
    let showPopup = false;

    function closeModal() {
        showModal.set(false);
    }

    function closeConfirmationPopup() {
        showPopup = false;
        console.log("Set showPopup to false")
    }

    function showConfirmationPopup() {
        console.log("Set showPopup to true")
        showPopup = true;
    }

    function toggleEditMode() {
        isEditingModal.set(!$isEditingModal);
        if (!$isEditingModal) {
            entryFormSelectedCategoriesIds.set([])
            entryFormSelectedTagsIds.set([])
            entryFormSelectedStatusesIds.set([])
            rowsCustomCat.set([{ id: 0, filled: false, parentId: '', elementId: '', newPar: false, newEl: true }])
        }
    }

    async function deleteEntry(entryId: string) {
        closeModal();
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/notes/${entryId}`, {
                method: 'DELETE',
            });
            $entries = await fetchNotes();
            window.dispatchEvent(new CustomEvent("fetchTimestamps"));

        } catch (error) {
            console.error("Error:", error);
        }
    }

    const handleOutsideClick = (event: MouseEvent) => {
        if ($showModal && container && !container.contains(event.target as Node) && !showPopup) {
            if (!$isEditingModal) {
                closeModal();
            }
        }
    };

    $: if ($selectedEntry) {
        selected = $selectedEntry;
    }

    onMount(() => {
        document.addEventListener('click', handleOutsideClick);
    });

    onDestroy(() => {
        document.removeEventListener('click', handleOutsideClick);
    });
</script>

{#if $selectedEntry}
    <div class="modal-backdrop">
        <div class="modal-content" bind:this={container} data-testid="extended-note">
            {#if isNote}
                {#if $isEditingModal}
                    <ModalEditingView
                            entry={selected}
                            on:edit={toggleEditMode}
                            on:close={closeModal}
                            on:closePopup={closeConfirmationPopup}
                            on:showPopup={showConfirmationPopup}
                            on:delete={() => deleteEntry($selectedEntry._id)}
                    />
                {:else}
                    <ModalDefaultView
                            entry={selected}
                            on:edit={toggleEditMode}
                            on:close={closeModal}
                            on:closePopup={closeConfirmationPopup}
                            on:showPopup={showConfirmationPopup}
                            on:delete={() => deleteEntry($selectedEntry._id)}
                    />
                {/if}
            {:else}
                {#if $isEditingModal}
                    <BookmarkEditingView
                            entry={selected}
                            on:edit={toggleEditMode}
                            on:close={closeModal}
                            on:closePopup={closeConfirmationPopup}
                            on:showPopup={showConfirmationPopup}
                            on:delete={() => deleteEntry($selectedEntry._id)}
                    />
                {:else}
                    <BookmarkDefaultView
                            entry={selected}
                            on:edit={toggleEditMode}
                            on:close={closeModal}
                            on:closePopup={closeConfirmationPopup}
                            on:showPopup={showConfirmationPopup}
                            on:delete={() => deleteEntry($selectedEntry._id)}
                    />
                {/if}
            {/if}
        </div>
    </div>

{/if}

{#if showPopup}
    <DeleteConfirmation
            bind:showPopup
            on:closePopup={closeConfirmationPopup}
            on:delete={() => deleteEntry($selectedEntry._id)}
    />
{/if}

<style>
    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    }

    .modal-content {
        background-color: #e0e6ef;
        padding: 20px;
        border-radius: 5px;
        max-width: 1000px;
        height: 70vh;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        overflow: auto;
    }
</style>