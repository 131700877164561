<script lang="ts">
    import {onDestroy} from "svelte";
    import Modal from "./Modal.svelte";
    import EntryForm from "./EntryForm.svelte";
    import EntrySvelte from "./Entry.svelte";
    import {
        showEntryForm,
        newTagsFromEntryFormTagSearch,
        tags,
        statuses,
        selectedTags,
        selectedStatuses,
        newStatusesFromEntryFormStatusSearch,
        newCustomCategoryNamesFromEntryFormAttributeSearch,
        newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch,
        newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch,
        customCategoryMap,
        type CustomCategoryElement,
        selectedCustomCategories,
        selectedCustomCategoryBarIds,
        customCategoryNames,
        categories, selectedCategories
    } from '../store';
    import { showModal } from "../store";
    import { entries, type Entry } from '../store';
    import { fetchNotes } from "./services/fetchNoteService";
    import { combinedStore } from '../store';
    import {fetchMetadata} from "./services/fetchMetaDataService";
    import {fetchTags} from "./services/fetchTagsService";
    import {fetchStatuses} from "./services/fetchStatusesService";
    import {fetchCustomCategoryNames} from "./services/fetchCustomCategoryNamesService";
    import {fetchCustomCategoryElements} from "./services/fetchCustomCategoryElementsService";
    import {buildCustomCategories} from "./services/buildCustomCategoriesService";
    import {fetchCategories} from "./services/fetchCategoriesService";
    import {buildCategoryTree} from "./services/buildCategoryTreeService";
    import {get} from "svelte/store";

    let debounceTimeout: ReturnType<typeof setTimeout>;

    export async function handleFetchNotes() {
        entries.set(await fetchNotes());
    }

    export function toggleForm() {
        showEntryForm.update((current) => !current);
    }

    async function handleFormSubmit(event) {
        showEntryForm.set(false);

        const { title, content, url, contentType, entryFormSelectedCategories,
            newSelectedCategoriesWithExistingParents, newSelectedCategoriesWithNewParents,
            statuses, tags, selectedCustomCategoriesForSubmit, timestamp } = event.detail;

        let metadata = { title: '', description: '', image: '', url: '' };
        if (contentType === "Bookmark") {
            try {
                // Use the reusable service to fetch metadata
                const fetchedMetadata = await fetchMetadata(url);
                if (fetchedMetadata) {
                    metadata = fetchedMetadata;
                } else {
                    console.warn(`Failed to fetch metadata for URL: ${content}`);
                }
            } catch (error) {
                console.error(`Error fetching metadata for URL ${content}:`, error);
            }
        }

        const formData = {
            title,
            content,
            url,
            contentType,
            selectedCategories: entryFormSelectedCategories,
            newSelectedCategoriesWithExistingParents,
            newSelectedCategoriesWithNewParents,
            statuses,
            newStatuses: $newStatusesFromEntryFormStatusSearch,
            tags,
            newTags: $newTagsFromEntryFormTagSearch,
            selectedCustomCategories: selectedCustomCategoriesForSubmit,
            newCustomCategoryNames: $newCustomCategoryNamesFromEntryFormAttributeSearch,
            newCustomCategoryElementsWithExistingParents: $newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch,
            newCustomCategoryElementsWithNewParents: $newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch,
            timestamp,
            metadata
        };

        try {
            const response = await fetch(`${import.meta.env.VITE_LINK}/notes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Failed to submit the form.');
            }

            /*const data = await response.json();
            //console.log('Success:', data.message);

            const notesArray = Object.values(data.allNotes);
            const mappedEntries: Entry[] = (await Promise.all(notesArray.map(async (note: any) => {
                return {
                    _id: note._id as string,
                    title: note.title as string,
                    value: note.content as string,
                    url: note.url as string,
                    contentType: note.contentType as string,
                    categories: note.categories as string[],
                    statuses: note.statuses as string[],
                    tags: note.tags as string[],
                    customCategories: note.customCategories as string[],
                    timestamp: note.timestamp || new Date() as Date,
                    metadata: note.metadata as MetaData
                };
            })));

            entries.set(mappedEntries);*/

            if ($newTagsFromEntryFormTagSearch.length > 0) {
                await fetchTags();
            }
            if ($newStatusesFromEntryFormStatusSearch.length > 0) {
                await fetchStatuses();
            }

            if ($newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch.length > 0 || $newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch.length > 0) {
                await fetchCustomCategoryNames();
                await fetchCustomCategoryElements();
                buildCustomCategories();
            }

            if (newSelectedCategoriesWithExistingParents.length > 0 || newSelectedCategoriesWithNewParents.length > 0) {
                await fetchCategories();
                buildCategoryTree($categories)

                const temp: string[] = [];
                $selectedCategories.forEach((item) => {
                    let keyFound = false;
                    Array.from($categories.entries()).forEach(([key, value]) => {
                        if (!keyFound) {
                            if (key === item) {
                                temp.push(key);
                                keyFound = true;
                            } else if (value.name === item) {
                                temp.push(value._id);
                                keyFound = true;
                            }
                        }
                    });
                });
                selectedCategories.set(temp);
            }

            window.dispatchEvent(new CustomEvent("fetchTimestamps"));

            $newTagsFromEntryFormTagSearch.forEach((item) => {
                for (const [key, tag] of $tags.entries()) {
                    if (tag.name === item) {
                        selectedTags.set([...$selectedTags, key]); // Add the key to selectedTags
                        break; // Exit the loop after finding the match
                    }
                }
            });

            $newStatusesFromEntryFormStatusSearch.forEach((item) => {
               for (const [key, status] of $statuses.entries()) {
                   if (status.name === item) {
                       selectedStatuses.set([...$selectedStatuses, key]);
                       break
                   }
               }
            });

            $newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch.forEach(([tempEl, tempPar]) => {
                const tempList = $customCategoryMap.get(tempPar) as CustomCategoryElement[];
                const item = tempList.find((item) => item.name === tempEl);
                if (item) {
                    if ($selectedCustomCategoryBarIds[0] === '') {
                        selectedCustomCategoryBarIds.set([item.parentId, $selectedCustomCategoryBarIds[1]])
                        selectedCustomCategories.set([...$selectedCustomCategories, [item._id, item.parentId]]);
                    } else if ($selectedCustomCategoryBarIds[1] === '') {
                        selectedCustomCategoryBarIds.set([$selectedCustomCategoryBarIds[0], item.parentId])
                        selectedCustomCategories.set([...$selectedCustomCategories, [item._id, item.parentId]]);
                    }
                }
            });

            $newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch.forEach(([tempEl, tempPar]) => {
                for (const [_, parent] of $customCategoryNames) {
                    if (parent.name === tempPar) {
                        const tempList = $customCategoryMap.get(parent._id) as CustomCategoryElement[];
                        const item = tempList.find((item) => item.name === tempEl);
                        if (item) {
                            if ($selectedCustomCategoryBarIds[0] === '') {
                                selectedCustomCategoryBarIds.set([item.parentId, $selectedCustomCategoryBarIds[1]])
                                selectedCustomCategories.set([...$selectedCustomCategories, [item._id, item.parentId]]);
                            } else if ($selectedCustomCategoryBarIds[1] === '') {
                                selectedCustomCategoryBarIds.set([$selectedCustomCategoryBarIds[0], item.parentId])
                                selectedCustomCategories.set([...$selectedCustomCategories, [item._id, item.parentId]]);
                            } else if ($selectedCustomCategoryBarIds[0] === item.parentId) {
                                selectedCustomCategories.set([...$selectedCustomCategories, [item._id, item.parentId]]);
                            } else if ($selectedCustomCategoryBarIds[1] === item.parentId) {
                                selectedCustomCategories.set([...$selectedCustomCategories, [item._id, item.parentId]]);
                            }
                        }
                        break
                    }
                }
            });

        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while submitting the form.');
        }
    }

    $: if ($combinedStore) {
        clearTimeout(debounceTimeout);

        debounceTimeout = setTimeout(() => {
            handleFetchNotes();
        }, 300);
    }

    let entriesForGrid: Entry[] = [];
    $: if (entries) {
        entriesForGrid = $entries
    }

    onDestroy(() => {
        clearTimeout(debounceTimeout);
    });
</script>

<div class="container-fluid entry-container content">
    <div class="row justify-content-left g-3 pb-5">
        {#each entriesForGrid as entry}
            <EntrySvelte entry={entry}/>
        {/each}
    </div>
</div>

{#if $showModal}
    <Modal />
{/if}

{#if $showEntryForm}
    <EntryForm on:submit={handleFormSubmit} on:cancel={() => showEntryForm.set(false)} />
{/if}

<style>
    .entry-container {
        position: relative;
        padding-bottom: 1rem;
        padding-top: 1rem;
        flex-grow: 1;
        overflow: auto;
    }
</style>
