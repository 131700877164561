<script lang="ts">
    import {createEventDispatcher} from "svelte";
    import {type Category} from "../store";
    import {buildCategoryTree} from "./services/buildCategoryTreeService";
    import EntryFormCategorySearchNewWindowCategoryElement
        from "./EntryFormCategorySearchNewWindowCategoryElement.svelte";

    export let categories: Map<string, Category> = new Map();
    export let newName: string = '';

    const dispatch = createEventDispatcher();

    let catTree = buildCategoryTree(categories);

    function submitSelected(event: CustomEvent) {
        categories.set(newName, new class implements Category {
            _id: string = newName;
            children: string[] = [];
            name: string = newName;
            parentId: string = event.detail.parent;
        })
        dispatch('submit', {categories})
    }

    function addNewCategory(event: CustomEvent) {
        categories.set(event.detail.name, new class implements Category {
            _id: string = event.detail.name;
            children: string[] = [];
            name: string = event.detail.name;
            parentId: string = event.detail.parent;
        })
        catTree = buildCategoryTree(categories);
    }

    function submitSelectedWithNoParent() {
        categories.set(newName, new class implements Category {
            _id: string = newName;
            children: string[] = [];
            name: string = newName;
            parentId: string = '';
        })
        dispatch('submit', {categories})
    }

    function close() {
        dispatch('close')
    }
</script>

<div class="modal-backdrop">
    <div class="modal show d-block" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modal-bg">
                <div class="modal-header">
                    <div class="fw-bold">
                        Select a Parent For the New Category
                    </div>
                    <button class="modal-button" on:click={close}>
                        <svg width="24" height="20">
                            <use href="#icon-close"/>
                        </svg>
                    </button>
                </div>

                <div class="modal-body">
                    <button class="no-parent-button rounded" on:click={submitSelectedWithNoParent}>
                        No Parent
                    </button>
                    {#each catTree as root}
                        <EntryFormCategorySearchNewWindowCategoryElement on:select={submitSelected}
                                                                         on:newValue={addNewCategory}
                                                                         category={root} categories={categories}/>
                    {/each}
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    .modal-content {
        background-color: #e0e6ef;
        padding: 20px;
        border-radius: 5px;
        max-width: 1500px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    }

    .modal-body {
        background-color: #d4dae5;
        max-height: 65vh;
        overflow: auto;
    }

    .modal-button {
        all: unset;
        font-size: 1.5rem;
        cursor: pointer;
        transition: transform 0.05s ease-in-out;
    }

    .no-parent-button {
        all: unset;
        margin-left: 0.5rem;
        margin-bottom: 0.5rem;
        cursor: pointer;
        padding: 0.5rem 1rem;
    }

    .no-parent-button:hover {
        background-color: #87888f;
    }
</style>