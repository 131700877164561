<script lang="ts">
    import {
        type CustomCategoryElement, customCategoryElements, customCategoryMap, type CustomCategoryName,
        customCategoryNames, selectedCustomCategories,
        selectedCustomCategoryBarIds,
        uiTopSectionHeight
    } from "../store";
    import {onDestroy, onMount} from "svelte";
    import CustomCategory from "./CustomCategoryElement.svelte";
    import {fetchCustomCategoryNames} from "./services/fetchCustomCategoryNamesService";
    import {buildCustomCategories} from "./services/buildCustomCategoriesService";
    import {fetchCustomCategoryElements} from "./services/fetchCustomCategoryElementsService";
    import {fetchNotes} from "./services/fetchNoteService";
    import {entries} from "../store.js";
    import InputField from "./InputField.svelte";

    export let id: number;

    let dropdownRef: HTMLElement;
    let isDropdownOpen = false;
    let isAdding = false;
    let addingNewCustomCategory = false;
    let editingCustomCategoryId = '';
    let editingCustomCategoryName = '';
    let emptyDropDownTextFiller = 'Custom Category';
    let dropdownText = emptyDropDownTextFiller;
    let customElements: CustomCategoryElement[] = [];

    async function fetchCustomCategoryElementsByParentId(parentId: string) {
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/customcategoryelements/parent/${parentId}`);
            if (response.ok) {
                const customRes = await response.json();
                return await Promise.all(
                    customRes.map(async (element: any) => {
                        return {
                            _id: element._id,
                            name: element.name,
                            parentId: element.parentId
                        };
                    })
                );
            } else {
                console.error("Failed to fetch CustomCategoryElements");
                return [];
            }
        } catch (error) {
            console.error("Error fetching CustomCategoryElements:", error);
            return [];
        }
    }

    async function addNewCustomCategory(newName: string) {
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/customcategorynames`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({name: newName})
            });

            if (!response.ok) {
                throw new Error('Failed to add new custom category');
            }
            await fetchCustomCategoryNames();
            buildCustomCategories();
        } catch (error) {
            console.error(error);
        }
    }

    async function handleAddCustomCategoryElement(elmentName: string, parentId: string) {
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/customcategoryelements`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({name: elmentName, parentId: parentId})
            });
            if (!response.ok) {
                throw new Error('Failed to add new element');
            }
        } catch (error) {
            console.error(error);
        }
        await fetchCustomCategoryElements();
        const temp: CustomCategoryElement[] = await fetchCustomCategoryElementsByParentId(parentId);
        $customCategoryMap.set(parentId, temp);
        customElements = $customCategoryMap.get(parentId) as CustomCategoryElement[];
    }

    async function editCustomCategoryName(newName: string, catId: string) {
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/customcategorynames/${catId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name:newName })
            });
            if (!response.ok) {
                throw new Error('Failed to update custom category name');
            }
            await fetchCustomCategoryNames();
            if ($selectedCustomCategoryBarIds[id] === catId) {
                dropdownText = ($customCategoryNames.get(catId) as CustomCategoryName).name
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function deleteCustomCategory(catId: string, catName: string) {
        selectedCustomCategories.set($selectedCustomCategories.filter(([_, parId]) => parId !== catId));
        if ($selectedCustomCategoryBarIds[0] === catId) {
            $selectedCustomCategoryBarIds[0] = '';
        } else if ($selectedCustomCategoryBarIds[1] === catId) {
            $selectedCustomCategoryBarIds[1] = '';
        }
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/customcategorynames/${catId}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Failed to delete custom category');
            }
            dropdownText = emptyDropDownTextFiller;
            await fetchCustomCategoryNames()
            await fetchCustomCategoryElements();
            buildCustomCategories();
        } catch (error) {
            console.error("Error deleting custom category:", error);
        }
    }

    async function deleteCustomCategoryElement(elementId: string, parentId: string) {
        selectedCustomCategories.set($selectedCustomCategories.filter(([elId, _]) => elId !== elementId));
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/customcategoryelements/${elementId}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Failed to delete custom category element');
            }
            const temp: CustomCategoryElement[] = await fetchCustomCategoryElementsByParentId(parentId);
            $customCategoryMap.set(parentId, temp);
            customElements = $customCategoryMap.get(parentId) as CustomCategoryElement[];
            entries.set(await fetchNotes());
        } catch (error) {
            console.error("Error deleting custom element:", error);
        }
    }

    async function editCustomCategoryElementById(elementId: string, parentId: string, name:string, oldName: string) {
        try {
            if (name !== oldName) {
                const response = await fetch(`${import.meta.env.VITE_LINK as string}/customcategoryelements/${elementId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({name})
                });
                if (!response.ok) {
                    throw new Error('Failed to update the element');
                }
                await fetchCustomCategoryElements();
                buildCustomCategories();
                customElements = $customCategoryMap.get(parentId) as CustomCategoryElement[];
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function handleSelection() {
        if ($selectedCustomCategoryBarIds[id] !== '') {
            customElements = $customCategoryMap.get($selectedCustomCategoryBarIds[id]) as CustomCategoryElement[];
        }
    }

    function handleClickOutside(event: MouseEvent) {
        if (dropdownRef && !dropdownRef.contains(event.target as Node)) {
            isDropdownOpen = false;
        }
    }

    let selectedId = ''
    $: if (selectedCustomCategoryBarIds) {
        if ($selectedCustomCategoryBarIds[id] !== '') {
            dropdownText = ($customCategoryNames.get($selectedCustomCategoryBarIds[id]) as CustomCategoryName).name
            selectedId = $selectedCustomCategoryBarIds[id];
            customElements = $customCategoryMap.get($selectedCustomCategoryBarIds[id]) as CustomCategoryElement[];
        } else {
            dropdownText = emptyDropDownTextFiller;
            selectedId = '';
            customElements = [];
        }
    }

    function handleEditCategoryNameInputSubmit(event: CustomEvent, oldName: string, catId: string) {
        editingCustomCategoryId = '';
        if (oldName !== event.detail.name) {
            editCustomCategoryName(event.detail.value, catId);
        }
    }

    function handleEditCategoryNameInputCancel() {
        editingCustomCategoryId = '';
    }

    function handleAddNewCategoryNameInputSubmit(event: CustomEvent) {
        addNewCustomCategory(event.detail.value);
        addingNewCustomCategory=false;
    }

    function handleAddNewCategoryNameInputCancel() {
        addingNewCustomCategory=false;
    }

    function handleAddNewCategoryElementInputSubmit(event: CustomEvent) {
        handleAddCustomCategoryElement(event.detail.value, $selectedCustomCategoryBarIds[id])
        isAdding = false;
    }

    function handleAddNewCategoryElementInputCancel() {
        isAdding = false;
    }

    onMount(() => {
        document.addEventListener('click', handleClickOutside);
    })

    onDestroy(() => {
        document.removeEventListener('click', handleClickOutside);
    });
</script>

<div class="main">
    <div class="header" bind:this={dropdownRef}>
        <div class="header-box">
            <button class="dropdown-header-button rounded" on:click={() => isDropdownOpen = !isDropdownOpen}>
                <span class="text">
                    {dropdownText}
                </span>
                <span class="dropdown-arrow">
                   ▼
                </span>
            </button>
            {#if $selectedCustomCategoryBarIds[id] !== ''}
                <button class="dropdown-header-add-new-button" on:click|stopPropagation={() => {
                                isAdding = true;
                            }}>
                    <svg width="15" height="15">
                        <use href="#icon-add"/>
                    </svg>
                </button>
            {/if}
        </div>
        {#if isDropdownOpen}
            <div class="custom-dropdown-container">
                <div class="custom-dropdown-menu rounded">
                    <ul class="list">
                        {#each $customCategoryNames as [key, custom]}
                            {#if editingCustomCategoryId !== custom._id}
                                <li class="element">
                                    <div class="d-flex">
                                        <button class="dropdown-button rounded" on:click={() => {
                                            dropdownText = custom.name;
                                            isDropdownOpen = false;
                                            $selectedCustomCategoryBarIds[id]=custom._id;
                                            if ($selectedCustomCategoryBarIds[(id+1)%2] === custom._id) {
                                                $selectedCustomCategoryBarIds[(id+1)%2] = '';
                                            }
                                            handleSelection();
                                        }}>
                                            <span class="text">
                                                {custom.name}
                                            </span>
                                        </button>
                                        <div class="action-buttons d-flex">
                                            <button on:click|stopPropagation={() => {
                                                    editingCustomCategoryId = custom._id; editingCustomCategoryName = custom.name;}}>
                                                <svg width="16" height="16">
                                                    <use href="#icon-edit-simple"></use>
                                                </svg>
                                            </button>
                                            <button on:click|stopPropagation={() => deleteCustomCategory(custom._id, custom.name)}>
                                                <svg width="16" height="16">
                                                    <use href="#icon-trash"></use>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            {:else}
                                <div>
                                    <InputField inputValue={editingCustomCategoryName} on:submit={(event) => handleEditCategoryNameInputSubmit(event, custom.name, custom._id)} on:cancel={handleEditCategoryNameInputCancel}/>
                                </div>
                            {/if}
                        {/each}
                    </ul>

                    {#if !addingNewCustomCategory}
                        <div class="add-new-custom-cat-name-section">
                            <button class="add-new-custom-cat-name-section-button" on:click|stopPropagation={() => addingNewCustomCategory = true}>
                                <svg width="15" height="15">
                                    <use href="#icon-add"/>
                                </svg>
                            </button>
                        </div>
                    {:else}
                        <div class="add-new-custom-cat-name-input-section">
                            <InputField placeholder="Name of the new element" on:submit={handleAddNewCategoryNameInputSubmit} on:cancel={handleAddNewCategoryNameInputCancel}/>
                        </div>
                    {/if}
                </div>
            </div>
        {/if}
    </div>

    <div class="elements">
        {#if isAdding}
            <div class="add-new-custom-cat-element-input-section">
                <InputField placeholder="Name of the new element" on:submit={handleAddNewCategoryElementInputSubmit} on:cancel={handleAddNewCategoryElementInputCancel}/>
            </div>
        {/if}
        {#if selectedId !== ''}
            <CustomCategory parentId={selectedId}
                            customCategoryElements={customElements}
                            on:edit={(event) => {editCustomCategoryElementById(event.detail.elementId, event.detail.parentId, event.detail.newName, event.detail.oldName)}}
                            on:delete={(event) => {deleteCustomCategoryElement(event.detail.elementId, event.detail.parentId)}}
            />
        {/if}
    </div>
</div>

<style>
    .elements {
        height: 40vh;
        overflow: auto;
    }

    .add-new-custom-cat-element-input-section {
        margin-left: 1rem;
        margin-top: 1rem;
    }

    .add-new-custom-cat-name-section-button {
        all: unset;
        cursor: pointer;
        text-align: center;
        padding-bottom: 0.1rem;
        padding-top: 0.1rem;
    }

    .add-new-custom-cat-name-section {
        padding-left: 1.65rem;
        padding-bottom: 0.775rem;
        padding-top: 0.55rem;
        border-top: 1px solid #615f5f;
    }

    .add-new-custom-cat-name-input-section {
        padding-top: 0.575rem;
        padding-bottom: 0.575rem;
        padding-left: 1.5rem;
        border-top: 1px solid #615f5f;
    }

    .dropdown-button {
        all: unset;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
        text-align: left;
        color: white;
        font-size: 14px;
        padding: 0.3rem 1rem;
        overflow: hidden;
        text-overflow: ellipsis
    }

    .dropdown-button:hover {
        background-color: #4a505e;
        color: white;
    }

    .custom-dropdown-container {
        box-sizing: border-box;
        width: 100%;
        padding-right: 1.5rem;
        position: absolute;
        z-index: 1001;
    }

    .custom-dropdown-menu {
        margin-top: 1px;
        background-color: #343a40;
        max-height: 25vh;
        display: flex;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
    }

    .list {
        list-style: none;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 0 0 0 0.5rem;
        padding: 0.5rem 0 0.5rem;
    }

    .text {
        flex: 1; /* Make the text span available space */
        overflow: hidden; /* Enable text overflow handling */
        white-space: nowrap; /* Prevent text wrapping to a new line */
        text-overflow: ellipsis; /* Add ellipsis for overflowing text */
    }

    .dropdown-arrow {
        flex-shrink: 0; /* Prevent the arrow from shrinking */
        margin-left: 0.5rem; /* Optional: Add spacing between text and arrow */
    }

    .dropdown-header-button {
        all: unset;
        display: flex; /* Use flexbox for alignment */
        justify-content: space-between; /* Ensure space between text and arrow */
        align-items: center; /* Vertically center the items */
        box-sizing: border-box;
        padding: 0.5rem 1rem;
        background-color: #2c313a;
        border: 2px solid #343a40;
        width: 100%; /* Ensure the button spans the full width */
        overflow: hidden; /* Enables text overflow handling */
        text-align: left; /* Align the text to the left */
    }

    .dropdown-header-add-new-button {
        all: unset;
        cursor: pointer;
        margin-left: 1rem;
    }

    .main {
        width: 100%;
        padding-right: 1rem;
        box-sizing: border-box;
        height: 45vh;
    }

    .header {
        width: 100%;
        box-sizing: border-box;
        position: relative;
    }

    .header-box {
        display: flex;
    }

    .action-buttons button {
        all: unset;
        display: inline-block;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        visibility: hidden;
    }

    .action-buttons button:hover {
        color: #007bff;
    }

    .action-buttons {
        display: flex;
        align-items: center;
        gap: 0.7rem;
        margin-left: 0.7rem;
    }

    .element:hover .action-buttons button{
        visibility: visible;
    }

    .action-buttons button:hover {
        color: #fff;
    }


</style>