<script lang="ts">
    import {categories, type Category, categoryTree, entries, selectedCategories} from "../store";
    import CategoryTree from './CategoryTree.svelte';
    import {addCategory} from "./services/addCategoryService";
    import {fetchCategories} from "./services/fetchCategoriesService";
    import {buildCategoryTree} from "./services/buildCategoryTreeService";
    import {fetchNotes} from "./services/fetchNoteService";
    import InputField from "./InputField.svelte";

    export let category: Category;
    let isAdding = false;
    let editingCategoryId = '';

    function toggleSelectCategory(categoryId: string) {
        selectedCategories.update(currentCategories => {
            if (currentCategories.includes(categoryId)) {
                return currentCategories.filter(selected => selected !== categoryId);
            } else {
                return [...currentCategories, categoryId];
            }
        });
    }

    async function handleAddCategory(newCategoryName:string) {
        await addCategory(newCategoryName, category._id);
        await fetchCategories();
        categoryTree.set(buildCategoryTree($categories));
    }

    function editCategory() {
        editingCategoryId = category._id;
    }

    async function deleteCategory(categoryId: string) {
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/categories/${categoryId}`, {
                method: 'DELETE',
            });
            await fetchCategories();
            categoryTree.set(buildCategoryTree($categories));
            $entries = await fetchNotes();
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    }

    async function editCategoryById(name:string) {
        try {
            if (name !== ($categories.get(category._id) as Category).name) {
                const response = await fetch(`${import.meta.env.VITE_LINK as string}/categories/${category._id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ name })
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message);
                }

                await fetchCategories();
                categoryTree.set(buildCategoryTree($categories));
                $entries = await fetchNotes();
            }
        } catch (error) {
            console.error(error);
        }
    }

    function getCategory(child: string) {
        return $categories.get(child) as Category;
    }

    function handleAddNewInputSubmit(event: CustomEvent) {
        isAdding = false;
        handleAddCategory(event.detail.value);
    }

    function handleAddNewInputCancel() {
        isAdding = false;
    }

    function handleEditInputSubmit(event: CustomEvent) {
        editingCategoryId = '';
        editCategoryById(event.detail.value)
    }

    function handleEditInputCancel() {
        editingCategoryId = '';
    }

</script>

<ul class="list">
    <li class="list-item rounded {$selectedCategories.includes(category._id) ? 'selected' : ''}">
        {#if editingCategoryId === category._id}
            <div class="editFieldSection">
                <InputField inputValue={category.name} on:submit={handleEditInputSubmit} on:cancel={handleEditInputCancel}/>
            </div>
        {:else}
            <div class="element d-flex">
                <button class="textelement" on:click={() => toggleSelectCategory(category._id)}>
                    <span class="text">
                        {category.name}
                    </span>
                </button>
                <div class="action-buttons d-flex">
                    <button class="category_buttons" on:click|stopPropagation={() => isAdding = true}>
                        <svg width="16" height="16">
                            <use href="#icon-add"/>
                        </svg>
                    </button>
                    <button class="category_buttons" on:click|stopPropagation={editCategory}>
                        <svg width="16" height="16">
                            <use href="#icon-edit-simple"/>
                        </svg>
                    </button>
                    <button class="category_buttons" on:click|stopPropagation={() => deleteCategory(category._id)}>
                        <svg width="16" height="16">
                            <use href="#icon-trash"/>
                        </svg>
                    </button>
                </div>
            </div>
        {/if}

        {#if isAdding}
            <div class="addNewFieldSection">
                <InputField placeholder="Add New Sub-Category" on:submit={handleAddNewInputSubmit} on:cancel={handleAddNewInputCancel}/>
            </div>
        {/if}
    </li>
    {#if category.children.length > 0}
        <ul class="sublist">
            {#each category.children as child}
                <CategoryTree category={getCategory(child)}/>
            {/each}
        </ul>
    {/if}
</ul>

<style>
    .addNewFieldSection {
        padding-left: 1rem;
    }

    .editFieldSection {
        padding-top: 0.187rem;
        padding-bottom: 0.187rem;
    }

    .list-item {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        background-color: #212529;
        box-sizing: border-box;
        text-align: left;
        padding: 0 0 0 0.5rem;
        margin-right: 1rem;
    }

    .list {
        list-style: none;
        margin: 0 0 0 1rem;
        padding: 0;
    }

    .sublist {
        margin: 0;
        padding: 0;
    }

    .list-item:hover {
        background-color: #1c2026;
    }

    .selected {
        background-color: #292f38;
    }

    .category_buttons {
        color: white;
    }

    .action-buttons {
        display: inline-flex;
    }

    .action-buttons button {
        display: inline-block;
        background: none;
        border: none;
        color: #fff; /* Icon color */
        font-size: 16px;
        margin-right: 8px;
        cursor: pointer;
    }

    .action-buttons button:hover {
        color: #fff; /* Highlight icon on hover */
    }

    /* Hide action buttons by default */
    .action-buttons {
        visibility: hidden;
    }

    /* Show buttons on hover */
    .list-item:hover .action-buttons {
        visibility: visible;
    }

    .textelement {
        all: unset;
        padding: 0.375rem 1.5rem 0.375rem 0;
        overflow: hidden;
        text-overflow: ellipsis
    }

    .element {
        overflow: hidden;
        text-overflow: ellipsis
    }

    .text {
        flex: 1; /* Make the text span available space */
        overflow: hidden; /* Enable text overflow handling */
        white-space: nowrap; /* Prevent text wrapping to a new line */
        text-overflow: ellipsis; /* Add ellipsis for overflowing text */
    }
</style>