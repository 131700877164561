<script lang="ts">
    import {handleLogin} from "./services/logoutHelper";
    import {navigate} from "svelte-routing";

    let errorMessage = "";
    let showPassword = false;

    async function onFormSubmit(event: Event) {
        event.preventDefault();
        const emailElem = document.getElementById('email') as HTMLInputElement;
        const passwordElem = document.getElementById('password') as HTMLInputElement;
        const email = emailElem.value.trim().toLowerCase();
        const password = passwordElem.value;

        errorMessage = "";

        const loginResult = await handleLogin(email, password);
        if (loginResult && loginResult.error) {
            errorMessage = "Invalid password or email";
            passwordElem.value = "";
        }
    }

    function goToCreateUser() {
        navigate('/signup');
    }

    function goToForgotPassword() {
        navigate('/forgot-password');
    }

    function togglePasswordVisibility() {
        showPassword = !showPassword;
    }
</script>

<div class="container-fluid vh-100">
    <div class="row h-100">
        <div class="col-md-4 d-flex justify-content-center align-items-center bg-dark text-white">
            <div class="w-75">
                <h2 class="mb-4 text-center">Log in to your account</h2>
                <form on:submit|preventDefault={onFormSubmit}>
                    <div class="mb-3">
                        <label for="email" class="form-label text-white">Email Address</label>
                        <input
                                type="email"
                                class="form-control bg-dark text-white border-secondary"
                                id="email"
                                placeholder="Enter your email"
                                required
                        />
                    </div>
                    <div class="mb-4">
                        <label for="password" class="form-label text-white">Password</label>
                        <div class="input-group">
                            <input
                                    type={showPassword ? "text" : "password"}
                                    class="form-control bg-dark text-white border-secondary"
                                    id="password"
                                    placeholder="Enter your password"
                                    required
                            />
                            <button class="input-group-text bg-dark border-secondary text-white" type="button" on:click={togglePasswordVisibility}>
                                {#if showPassword}
                                    <!-- Eye open icon -->
                                    <svg width="24" height="24">
                                        <use href="#eye-open" />
                                    </svg>
                                {:else}
                                    <!-- Eye closed icon -->
                                    <svg width="24" height="24">
                                        <use href="#eye-closed" />
                                    </svg>
                                {/if}
                            </button>
                        </div>
                    </div>
                    {#if errorMessage}
                        <div class="text-danger mb-3">{errorMessage}</div>
                    {/if}
                    <button type="submit" class="btn btn-primary w-100" data-testid="login-button">Log In</button>
                </form>
                <div class="mt-3 text-center">
                    <!-- <a href="#" class="text-decoration-none text-white" on:click={goToCreateUser}>Sign Up </a> | --> <!--COMMENTED OUT FOR DEPLOYMENT-->
                    <a href="#" class="text-decoration-none text-white" on:click={goToForgotPassword}> Forgot Password?</a>
                </div>
            </div>
        </div>
        <div class="col-md-8 d-none d-md-block p-0 position-relative">
            <div class="bg-image">
                <div class="image-content text-white text-center">
                    <h1 class="display-4">Timeline</h1>
                    <p class="fs-3">Knowledge management and personal archive</p>
                    <p class="h6 lead">
                        Organize your notes and bookmarks with ease and see them come to life on a timeline...
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<svg style="display:none;">
    <symbol id="eye-open" viewBox="0 0 24 24">
        <path d="M12 5C7 5 2.9 9.1 1 12c1.9 2.9 6 7 11 7s9.1-4.1 11-7c-1.9-2.9-6-7-11-7zm0 12c-2.8 0-5-2.2-5-5s2.2-5
        5-5 5 2.2 5 5-2.2 5-5 5z"/>
    </symbol>
    <symbol id="eye-closed" viewBox="0 0 24 24">
        <path d="M12 5C7 5 2.9 9.1 1 12l2.7 3.1C5.3 18 8.4 20 12 20c4.9 0 9.1-4.1 11-7-1.9-2.9-6-7-11-7zm0
        12c-2.8 0-5-2.2-5-5 0-.7.1-1.4.4-2L5.1 12l-.6-.7C2.9 9.9 7 5 12 5c3.6 0 6.7 2 8.9 4.3L19 12l-1.3
        -1.5c.3.6.4 1.3.4 2 0 2.8-2.2 5-5 5z"/>
    </symbol>
</svg>

<style>
    .bg-image {
        position: relative;
        width: 100%;
        height: 100%;
        background-image: url('https://images.unsplash.com/photo-1579548122080-c35fd6820ecb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .bg-image::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .image-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        padding: 0 2rem;
    }

    .col-md-8 {
        position: relative;
        overflow: hidden;
    }

    .form-control {
        background-color: #2c2c2c;
        border-color: #555;
        color: #fff;
    }

    .form-control::placeholder {
        color: #bbb;
    }

    .input-group .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group-text {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .input-group-text svg {
        fill: currentColor;
    }
</style>
