<script lang="ts">
    import {
        categories,
        type CustomCategoryElement,
        customCategoryElements, customCategoryMap, customCategoryNames,
        entries,
        type Entry,
        entryFormSelectedCategoriesIds,
        entryFormSelectedStatusesIds,
        entryFormSelectedTagsIds,
        newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch,
        newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch,
        newCustomCategoryNamesFromEntryFormAttributeSearch,
        newStatusesFromEntryFormStatusSearch,
        newTagsFromEntryFormTagSearch,
        rowsCustomCat, selectedCategories,
        selectedEntry
    } from "../store";
    import {createEventDispatcher} from "svelte";
    import EntryFormAtributeSearch from "./EntryFormAttributeSearch.svelte";
    import EntryFormStatusSearch from "./EntryFormStatusSearch.svelte";
    import EntryFormCateorySearch from "./EntryFormCateorySearch.svelte";
    import EntryFormTagStatusSearch from "./EntryFormTagSearch.svelte";
    import {fetchMetadata} from "./services/fetchMetaDataService";
    import {fetchTags} from "./services/fetchTagsService";
    import {fetchStatuses} from "./services/fetchStatusesService";
    import {fetchCustomCategoryNames} from "./services/fetchCustomCategoryNamesService";
    import {fetchCustomCategoryElements} from "./services/fetchCustomCategoryElementsService";
    import {buildCustomCategories} from "./services/buildCustomCategoriesService";
    import {fetchNotes} from "./services/fetchNoteService";
    import {get} from "svelte/store";
    import EntryFormCategorySearchNewWindow from "./EntryFormCategorySearchNewWindow.svelte";
    import {fetchCategories} from "./services/fetchCategoriesService";
    import {buildCategoryTree} from "./services/buildCategoryTreeService";

    export let entry: Entry;

    const dispatch = createEventDispatcher();

    let entryTitle: string = entry.title;
    let entryValue: string = entry.value;
    let entryURL: string = entry.url;
    let entryDate: string = entry.timestamp.toISOString().split("T")[0];
    const entryDateOriginal = entryDate;

    let isURLValid = false;

    let showURLErrorMessage = false;
    let hasURLBeenTouched = false;

    let showContentErrorMessage = false;
    let showCategoryErrorMessage = false;

    let hasContentBeenTouched = false;
    let hasCategoryBeenTouched = false;

    let tempCategories = new Map([...get(categories)]);
    let tempName = '';
    let newCategoryWindowOpen = false;
    let newParentSelected = false;

    async function submitEdit() {
        hasCategoryBeenTouched = true;
        hasURLBeenTouched = true;

        showCategoryErrorMessage = $entryFormSelectedCategoriesIds.length === 0;
        showURLErrorMessage = !isURLValid;

        if (showURLErrorMessage || showCategoryErrorMessage) {
            return;
        }
        showCategoryErrorMessage = false;
        showURLErrorMessage = false;

        const title = entryTitle;
        const content = entryValue;
        const url = entryURL;
        const timestamp = new Date(entryDate);
        const statuses = $entryFormSelectedStatusesIds || [];
        const tags = $entryFormSelectedTagsIds || [];
        const customCategories = $rowsCustomCat.filter(row => row.elementId !== '' && !(row.newPar || row.newEl)).map(row => row.elementId);

        let metadata: { title: string; description: string; image: string; url: string } | null = {
            title: "",
            description: "",
            image: "",
            url: ""
        };

        if (entry.contentType === "Bookmark") {
            metadata = await fetchMetadata(url);
        }

        let existingCategories: string[] = [];
        let newCategoriesWithNewParent: string[][] = [];
        let newCategoriesWithExistingParent: string[][] = [];

        Array.from(tempCategories.entries()).forEach(([key, value]) => {
            if (key === value.name) {
                if (value.parentId === '') {
                    newCategoriesWithExistingParent.push([value.name, value.parentId]);
                } else {
                    if (existingCategories.includes(value.parentId)) {
                        newCategoriesWithExistingParent.push([value.name, value.parentId]);
                    } else {
                        newCategoriesWithNewParent.push([value.name, value.parentId]);
                    }
                }
            } else {
                existingCategories.push(key);
            }
        });

        categories.set(new Map([...tempCategories]));


        const note = {
            title,
            content,
            url,
            categories: $entryFormSelectedCategoriesIds || [],
            newSelectedCategoriesWithExistingParents: newCategoriesWithExistingParent,
            newSelectedCategoriesWithNewParents: newCategoriesWithNewParent,
            statuses,
            newStatuses: $newStatusesFromEntryFormStatusSearch,
            tags,
            newTags: $newTagsFromEntryFormTagSearch,
            selectedCustomCategories: customCategories,
            newCustomCategoryNames: $newCustomCategoryNamesFromEntryFormAttributeSearch,
            newCustomCategoryElementsWithExistingParents: $newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch,
            newCustomCategoryElementsWithNewParents: $newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch,
            metadata: metadata,
            timestamp: timestamp
        };

        dispatch('edit')

        //console.log(note);

        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/notes/${$selectedEntry._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(note)
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Server error: ${response.status} - ${errorText}`);
            }

            if (entryDate !== entryDateOriginal){
                entries.set(await fetchNotes());
            }

            const responseData = await response.json();
            //console.log('Note updated successfully:', responseData);

            entries.update(currentEntries => {
                return currentEntries.map(entry => {
                    if (entry._id === responseData._id) {
                        return {
                            ...entry,
                            title: responseData.title,
                            value: responseData.content,
                            url: responseData.url,
                            contentType: responseData.contentType,
                            categories: responseData.categories,
                            statuses: responseData.statuses,
                            tags: responseData.tags,
                            customCategories: responseData.customCategories,
                            timestamp: new Date(responseData.timestamp),
                            metadata: {
                                ...$selectedEntry.metadata,
                                ...responseData.metadata
                            }
                        };
                    }
                    return entry;
                });
            });

            if ($newTagsFromEntryFormTagSearch.length > 0) {
                await fetchTags();
            }
            if ($newStatusesFromEntryFormStatusSearch.length > 0) {
                await fetchStatuses();
            }

            if ($newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch.length > 0 || $newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch.length > 0) {
                await fetchCustomCategoryNames();
                await fetchCustomCategoryElements();
                buildCustomCategories();
            }

            if (newCategoriesWithExistingParent.length > 0 || newCategoriesWithNewParent.length > 0) {
                await fetchCategories();
                buildCategoryTree($categories)

                const temp: string[] = [];
                $selectedCategories.forEach((item) => {
                    let keyFound = false;
                    Array.from($categories.entries()).forEach(([key, value]) => {
                        if (!keyFound) {
                            if (key === item) {
                                temp.push(key);
                                keyFound = true;
                            } else if (value.name === item) {
                                temp.push(value._id);
                                keyFound = true;
                            }
                        }
                    });
                });
                selectedCategories.set(temp);
            }

            let tempCustomCategories = [...responseData.customCategories]

            $newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch.forEach(([tempEl, tempPar]) => {
                const tempList = $customCategoryMap.get(tempPar) as CustomCategoryElement[];
                const item = tempList.find((item) => item.name === tempEl);
                if (item) {
                    tempCustomCategories = [...tempCustomCategories, item._id];
                }
            });

            $newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch.forEach(([tempEl, tempPar]) => {
                for (const [_, parent] of $customCategoryNames) {
                    if (parent.name === tempPar) {
                        const tempList = $customCategoryMap.get(parent._id) as CustomCategoryElement[];
                        const item = tempList.find((item) => item.name === tempEl);
                        if (item) {
                            tempCustomCategories = [...tempCustomCategories, item._id];
                        }
                        break
                    }
                }
            });

            $selectedEntry.title = responseData.title;
            $selectedEntry.value = responseData.content;
            $selectedEntry.url = responseData.url;
            $selectedEntry.categories = responseData.categories;
            $selectedEntry.statuses = responseData.statuses;
            $selectedEntry.tags = responseData.tags;
            $selectedEntry.customCategories = tempCustomCategories;
            $selectedEntry.metadata = {
                                ...$selectedEntry.metadata,
                                ...responseData.metadata
                                }
            $selectedEntry.timestamp = timestamp;

            window.dispatchEvent(new CustomEvent("fetchTimestamps"));

            entryFormSelectedCategoriesIds.set([])
            entryFormSelectedTagsIds.set([])
            entryFormSelectedStatusesIds.set([])
            rowsCustomCat.set([{ id: 0, filled: false, parentId: '', elementId: '', newPar: false, newEl: false }]);
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    entryFormSelectedCategoriesIds.set(entry.categories);
    entryFormSelectedTagsIds.set(entry.tags);
    entryFormSelectedStatusesIds.set(entry.statuses);
    rowsCustomCat.set((() => {
        if (entry.customCategories.length == 0) {
            return [{ id: 0, filled: false, parentId: '', elementId: '', newPar: false, newEl: false }];
        } else {
            const temp:{id: number; filled: boolean; parentId: string; elementId: string, newPar: boolean, newEl:boolean }[] = [];
            let tempId = 0;
            entry.customCategories.forEach((elementId) => {
                const parentId = ($customCategoryElements.get(elementId) as CustomCategoryElement).parentId;
                temp.push({ id: tempId, filled: true, parentId: parentId, elementId: elementId, newPar: false, newEl: false})
                tempId++;
            });
            return [...temp, { id: tempId, filled: false, parentId: '', elementId: '', newPar: false, newEl: false }];
        }
    })())

    function preventClear(event: Event) {
        const input = event.target as HTMLInputElement;
        entryDate = input.value;
    }

    function validateURL(url: string) {
        const urlPattern = /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?$/;
        return urlPattern.test(url);
    }

    function handleURLInput() {
        hasURLBeenTouched = true;
    }

    // category error
    $: showCategoryErrorMessage = hasCategoryBeenTouched && $entryFormSelectedCategoriesIds.length === 0;
    // url error
    $: {
        isURLValid = validateURL(entryURL);
        showURLErrorMessage = hasURLBeenTouched && !isURLValid;
    }

    function searchToWindow(event: CustomEvent) {
        newParentSelected = false;
        tempCategories = event.detail.categories
        tempName = event.detail.name
        newCategoryWindowOpen = true;
    }

    function windowToSearch(event: CustomEvent) {
        tempCategories = event.detail.categories;
        newCategoryWindowOpen = false;
        newParentSelected = true;
    }

    function close() {
        newCategoryWindowOpen = false;
        tempName = '';
        tempCategories = new Map([...get(categories)]);
        newParentSelected = false;
    }
</script>

<div class="modal-header">
    <div class="d-flex justify-content-between align-items-center">
        <input class="form-control text-border flex-grow-1 me-2 px-2 py-1 border rounded" type="text" bind:value={entryTitle}/>

        <div class="form-group">
            <input
                    id="date"
                    type="date"
                    class="form-control"
                    bind:value={entryDate}
                    on:blur={preventClear}
                    required
                    data-testid="add-entry-date"
            />
        </div>
    </div>

    <div>
        <button class="modal-button" on:click|stopPropagation={() => dispatch('showPopup')}>
            <svg width="24" height="20">
                <use href="#icon-trash"/>
            </svg>
        </button>
        <button class="modal-button" on:click|stopPropagation={() => dispatch('close')}>
            <svg width="24" height="20">
                <use href="#icon-close"/>
            </svg>
        </button>
    </div>
</div>

<div class="modal-body d-flex flex-column h-100 position-relative mt-3">
    <input
            id="url"
            type="text"
            class="form-control {showURLErrorMessage ? 'error-border' : ''}"
            placeholder="URL"
            on:blur={handleURLInput}
            on:input={handleURLInput}
            bind:value={entryURL}
            required
    />
    {#if showURLErrorMessage}
        <span style="color: red; margin-top: 4px;">Invalid URL</span>
    {/if}
    <textarea
            id="content"
            class="form-control text-border flex-grow-1 me-2 px-2 py-1 border rounded h-100 mt-2"
            placeholder="Notes"
            style="resize: none;"
            bind:value={entryValue}
            required
    />
</div>

    <div class="mt-2">
        <EntryFormCateorySearch
                showCategoryErrorMessage={showCategoryErrorMessage}
                bind:hasCategoryBeenTouched
                tempCategories={tempCategories}
                on:new={searchToWindow}
                tempName={tempName}
                newParentSelected={newParentSelected}
        />
        <EntryFormTagStatusSearch/>
        <EntryFormStatusSearch/>
        <EntryFormAtributeSearch/>
    </div>

<div class="modal-footer">
    <button class="btn btn-secondary" on:click|stopPropagation={() => dispatch('edit')}>Cancel</button>
    <button class="btn btn-primary ms-2" on:click|stopPropagation={() => submitEdit()}>Submit</button>
</div>

{#if newCategoryWindowOpen}
    <EntryFormCategorySearchNewWindow on:submit={windowToSearch} on:close={close} categories={tempCategories} newName={tempName}/>
{/if}

<style>
    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .error-border {
        border: 1px solid red !important;
    }

    .modal-title {
        margin: 0;
    }

    .modal-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        transition: transform 0.05s ease-in-out;
    }

    .modal-button:hover {
        transform: scale(1.1);
    }

    .title {
        gap: 0.15rem;
    }

    .types {
        background-color: #d4dae5;
        margin-bottom: 0.5rem;
        padding: 0.15rem 0.25rem;
        margin-left: 0.5rem;
    }

    .chip {
        margin-left: 0.25rem;
        height: 2rem;
        background-color: #6d7583;
    }

</style>