<script lang="ts">
    import {onDestroy, onMount} from "svelte";
    import {entries, selectedStatuses, type Status, statuses} from '../store';
    import {fetchStatuses} from "./services/fetchStatusesService";
    import {fetchNotes} from "./services/fetchNoteService";
    import InputField from "./InputField.svelte";

    let searchQuery = '';
    let isAddingNew = false;
    let isDropDownOpen = false;
    let container: HTMLElement;
    let dropdownDiv: HTMLElement;
    let addNewSectionDiv: HTMLElement;
    let listElement: HTMLElement;
    let newElementName = '';
    let editElementName = '';
    let editElementId = '';
    let validStatuses: Status[] = [];

    async function handleAddNewElement() {
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/statuses`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({name: newElementName})
            });
        } catch (error) {
            console.error(error);
        }
    }

    function addStatusSelection(statusId: string): void {
        selectedStatuses.update(currentStatuses => {
            searchQuery = ''
            return [...currentStatuses, statusId];
        });
        isDropDownOpen = false;
    }

    $: validStatuses = (() => {
        const valid: Status[] = [];
        $statuses.forEach((status, key) => {
            if (!$selectedStatuses.includes(key)) {
                if (status.name.toLowerCase().includes(searchQuery.toLowerCase())) {
                    valid.push(status);
                }
            }
        });
        return valid;
    })();

    const handleOutsideClick = (event: MouseEvent) => {
        if (container && !container.contains(event.target as Node)) {
            isDropDownOpen = false;
            isAddingNew = false;
            newElementName = '';
        }
    };

    async function handleAddNew() {
        isAddingNew = true;
    }

    async function deleteStatus(id: string) {
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/statuses/${id}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Failed to delete status');
            }
            await fetchStatuses();
            entries.set(await fetchNotes());
        } catch (error) {
            console.error("Error deleting status:", error);
        }
    }

    async function editStatus(id: string, newName: string) {
        try {
            if (newName !== ($statuses.get(id) as Status).name) {
                const response = await fetch(`${import.meta.env.VITE_LINK as string}/statuses/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({name: newName})
                });
                if (!response.ok) {
                    throw new Error('Failed to update the status.');
                }
                await fetchStatuses();
                entries.set(await fetchNotes());
            }
        } catch (error) {
            console.error("Error editing status:", error);
        }
    }

    function scrollToBottom() {
        listElement.scrollTop = listElement.scrollHeight;
    }

    async function handleInputSubmit (event: CustomEvent) {
        isAddingNew = false
        newElementName = event.detail.value;
        await handleAddNewElement();
        await fetchStatuses();
        scrollToBottom()
    }

    function handleInputCancel () {
        isAddingNew = false
    }

    function handleEditInputSubmit(event: CustomEvent) {
        editElementName = event.detail.value;
        const temp1 = editElementId;
        const temp2 = editElementName;
        editElementId = '';
        editElementName = '';
        editStatus(temp1, temp2);
    }

    function handleEditInputCancel() {
        editElementId = '';
        editElementName = '';
    }

    let listHeight = 0;
    $: if (dropdownDiv && addNewSectionDiv) {
        listHeight = (window.innerHeight * (30.0 / 100)) - addNewSectionDiv.clientHeight;
    }

    onMount(() => {
        document.addEventListener('click', handleOutsideClick);
    });

    onDestroy(() => {
        document.removeEventListener('click', handleOutsideClick);
    });
</script>

<div class="dropdown" bind:this={container}>
    <div class="search position-relative">
        <input
                type="text"
                name="SearchField"
                placeholder="Search Statuses"
                bind:value={searchQuery}
                class="search-input rounded bg-dark"
                on:focus={() => isDropDownOpen = true}
        />
        {#if isDropDownOpen}
            <div class="custom-dropdown-menu rounded" bind:this={dropdownDiv}>
                <ul class="list" bind:this={listElement} style={`max-height: ${listHeight}px;`}>
                    {#each validStatuses as status}
                        <li class="element">
                            {#if editElementId !== status._id}
                                <div class="d-flex align-items-left">
                                    <button class="dropdown-item rounded"
                                            on:click={() => addStatusSelection(status._id)} data-testid="tags-dropdown">
                                        {status.name}
                                    </button>
                                    <div class="action-buttons d-flex">
                                        <button class="category_buttons" on:click|stopPropagation={() => {editElementId = status._id; editElementName = status.name}}>
                                            <svg width="16" height="16">
                                                <use href="#icon-edit-simple" />
                                            </svg>
                                        </button>
                                        <button class="category_buttons" on:click|stopPropagation={() => deleteStatus(status._id)}>
                                            <svg width="16" height="16">
                                                <use href="#icon-trash" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            {:else}
                                <div class="editFieldSection">
                                    <InputField inputValue={status.name} on:submit={handleEditInputSubmit} on:cancel={handleEditInputCancel}/>
                                </div>
                            {/if}
                        </li>
                    {/each}
                </ul>
                {#if !isAddingNew}
                    <div class="addNewSection" bind:this={addNewSectionDiv}>
                        <button class="custom-button" on:click|stopPropagation={() => {
                        isAddingNew = true;
                        handleAddNew();
                    }}>
                            <svg width="15" height="15">
                                <use href="#icon-add"/>
                            </svg>
                        </button>
                    </div>
                {:else}
                    <div class="addNewFieldSection" bind:this={addNewSectionDiv}>
                        <InputField placeholder="Add New Status" on:submit={handleInputSubmit} on:cancel={handleInputCancel}/>
                    </div>
                {/if}
            </div>
        {/if}
    </div>
</div>

<style>
    .editFieldSection {
        padding: 0.112rem 0 0.112rem 1rem;
    }

    .addNewFieldSection {
        padding-top: 0.575rem;
        padding-bottom: 0.575rem;
        padding-left: 1.5rem;
        border-top: 1px solid #615f5f;
    }

    .addNewSection {
        padding-left: 1.65rem;
        padding-bottom: 0.775rem;
        padding-top: 0.55rem;
        border-top: 1px solid #615f5f;
    }

    .custom-button {
        all: unset;
        cursor: pointer;
        text-align: center;
        padding-bottom: 0.1rem;
        padding-top: 0.1rem;
    }

    .action-buttons {
        visibility: hidden;
    }

    .action-buttons button {
        background: none;
        border: none;
        color: #fff;
        font-size: 16px;
        margin-right: 8px;
        cursor: pointer;
    }

    .action-buttons button:hover {
        color: #fff;
    }

    .element:hover .action-buttons {
        visibility: visible;
    }

    .search-input {
        border: 2px solid #2c313a; /* Set the border to solid white */
        padding: 0.5rem; /* Padding inside the input */
        width: 33vw; /* Set a specific width */
        background-color: #343a40; /* Match the dark background */
        color: #ffffff; /* Set text color to white */
    }

    .search-input::placeholder {
        color: #ffffff;
    }

    .custom-dropdown-menu {
        position: absolute;
        margin-top: 3px;
        background-color: #343a40;
        border-radius: 2px;
        z-index: 1001;
        max-height: 30vh;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        max-width: 33vw;
        width: 33vw;
    }

    .list {
        margin: 0 0 0 0.5rem;
        padding: 0.5rem 0 0.5rem 0;
        list-style: none;
        overflow: auto;
    }

    .dropdown-item {
        all: unset;
        color: #ddd;
        padding: 0.3rem 1rem;
        text-decoration: none;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis
    }

    .dropdown-item:hover {
        background-color: #4a505e;
        color: white;
    }

    .dropdown {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 33vw;
        width: 33vw;
    }
</style>