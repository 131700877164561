<script lang="ts">
    import {
        selectedCategories,
        selectedTags,
        selectedStatuses,
        categories,
        tags,
        statuses,
        type Category,
        type Tag,
        type Status,
        type CustomCategoryElement,
        customCategoryElements,
        selectedCustomCategories, customCategoryNames, type CustomCategoryName
    } from "../store";

    function removeSelectedCategory(elementId: string) {
        selectedCategories.update(currentCategories => {
            return currentCategories.filter(t => t !== elementId);
        });
    }

    function removeSelectedTag(elementId: string) {

        selectedTags.update(currentTags => {
            return currentTags.filter(t => t !== elementId);
        });
    }

    function removeSelectedStatus(elementId: string) {
        selectedStatuses.update(currentStatuses => {
            return currentStatuses.filter(t => t !== elementId);
        });
    }

    function removeSelectedCustomCategory(elementId: string) {
        selectedCustomCategories.update(currentCustomCategories => {
            return currentCustomCategories.filter(([element, _]) => element !== elementId)
        });
    }

    function removeAllSelectedCategories() {
        selectedCategories.set([]);
    }

    function removeAllSelectedTags() {
        selectedTags.set([]);
    }

    function removeAllSelectedStatuses() {
        selectedStatuses.set([]);
    }

    function removeAllSelectedCustomCategories(parentId: string) {
        selectedCustomCategories.update(custom => {
            return custom.filter(([_, parent]) => parent !== parentId)
        })
    }

    $: selectedCategoriesObjects = $selectedCategories.map(id => $categories.get(id) as Category).filter(item => item !== undefined);

    $: selectedTagsObjects = $selectedTags.map(id => $tags.get(id) as Tag)

    $: selectedStatusesObjects = $selectedStatuses.map(id => $statuses.get(id) as Status);

    $: customCategoryMap = (() => {
        const map: Map<string, CustomCategoryElement[]> = new Map();
        $selectedCustomCategories.forEach(([elementId, parentId]) => {
            const elementObj = $customCategoryElements.get(elementId) as CustomCategoryElement;
            if (!map.get(parentId)) {
                map.set(parentId, [elementObj])
            } else {
                map.get(parentId)!.push(elementObj)
            }
        })
        return map;
    })();

    function getParent(parentId: string) {
        return $customCategoryNames.get(parentId) as CustomCategoryName;
    }

    function getElements(parentId: string) {
        return customCategoryMap.get(parentId) as CustomCategoryElement[];
    }
</script>

<div class="filters d-flex flex-wrap">
    {#if $selectedCategories.length > 0}
        <div class="types d-flex align-items-center rounded flex-wrap text-white">
            <div class="title">
                Categories:
            </div>

            {#each selectedCategoriesObjects as selectedCategory}
                <button class="chip px-3 py-2 bg-dark rounded d-flex align-items-center text-white"
                    on:click={() => removeSelectedCategory(selectedCategory._id)}>
                    {selectedCategory.name}
                    <span class="close">&times;</span>
                </button>
            {/each}

            <button class="close" on:click={() => removeAllSelectedCategories()}>&times;</button>
        </div>
    {/if}

    {#if $selectedTags.length > 0}
        <div class="types d-flex align-items-center rounded flex-wrap text-white">
            <div class="title">
                Tags:
            </div>

            {#each selectedTagsObjects as selectedTag}
                <button class="chip px-3 py-2 bg-dark rounded d-flex align-items-center text-white"
                     on:click={() => removeSelectedTag(selectedTag._id)}>
                    {selectedTag.name}
                    <span class="close">&times;</span>
                </button>
            {/each}

            <button class="close" on:click={() => removeAllSelectedTags()}>&times;</button>
        </div>
    {/if}

    {#if $selectedStatuses.length > 0}
        <div class="types d-flex align-items-center rounded flex-wrap text-white">
            <div class="title">
                Statuses:
            </div>

            {#each selectedStatusesObjects as selectedStatus}
                <button class="chip px-3 py-2 bg-dark rounded d-flex align-items-center text-white"
                     on:click={() => removeSelectedStatus(selectedStatus._id)}>
                    {selectedStatus.name}
                    <span class="close">&times;</span>
                </button>
            {/each}

            <button class="close" on:click={() => removeAllSelectedStatuses()}>&times;</button>
        </div>
    {/if}

    {#if $selectedCustomCategories.length > 0}
        {#each Array.from(customCategoryMap.keys()) as parent}
            <div class="types d-flex align-items-center rounded flex-wrap text-white">
                <div class="title">
                    {getParent(parent).name}:
                </div>
                {#each getElements(parent) as element}
                    <button class="chip px-3 py-2 bg-dark rounded d-flex align-items-center text-white"
                            on:click={() => removeSelectedCustomCategory(element._id)}>
                        {element.name}
                        <span class="close">&times;</span>
                    </button>
                {/each}

                <button class="close" on:click={() => removeAllSelectedCustomCategories(parent)}>&times;</button>
            </div>
        {/each}
    {/if}
</div>

<style>
    .types {
        background-color: #2c313a;
        margin-bottom: 0.5rem;
        padding: 0.15rem 0.25rem;
        margin-left: 0.5rem;
    }

    .title {
        background-color: #2c313a;
        gap: 0.15rem;
    }

    .chip {
        all: unset;
        margin-left: 0.25rem;
        height: 1.5rem;
    }

    .filters {
        background-color: #1c1e21;
        gap: 0.15rem;
    }

    .close {
        all: unset;
        cursor: pointer;
        margin-left: 5px;
    }
</style>