<script lang="ts">
    import {createEventDispatcher, onDestroy, onMount} from "svelte";

    const dispatch = createEventDispatcher();
    export let showPopup = false;
    let popUp: HTMLElement;

    const handleOutsideClick = (event: MouseEvent) => {
        if (showPopup && popUp && !popUp.contains(event.target as Node)){
            dispatch('closePopup');
        }
    };

    onMount(() => {
        document.addEventListener('click', handleOutsideClick);
    });

    onDestroy(() => {
        document.removeEventListener('click', handleOutsideClick);
    });
</script>
<div class="modal-backdrop">
    <div class="modal-content" bind:this={popUp}>
        <div class="d-flex justify-content-center align-items-center text-center mt-1">
            <h5 class="modal-title fw-bold">Delete this entry?</h5>
        </div>
        <div class="modal-buttons">
            <button
                    class="modal-button btn btn-secondary"
                    on:click|stopPropagation={() => dispatch('closePopup')}
                    data-testid="popup-close"
            >
                No
            </button>
            <button
                    class="modal-button btn btn-primary"
                    on:click|stopPropagation={() => dispatch('delete')}
                    data-testid="popup-delete"
            >
                Yes
            </button>
        </div>
    </div>
</div>

<style>
    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1100;
    }
    .modal-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        background-color: #e0e6ef;
        padding: 10px;
        border-radius: 5px;
        max-width: 300px;
        min-width: 215px;
        width: 40vw;
        height: 8.5rem;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        overflow: auto;
    }

    .modal-buttons {
        display: flex;
        gap: 10px;
        justify-content: center;
        margin-top: 20px;
        padding: 0 20px;
        width: 100%;
        box-sizing: border-box;
    }

    .modal-button {
        flex: 1;
        padding: 10px 20px;
        font-size: 16px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-weight: bold;
        text-align: center;
    }
</style>