<script lang="ts">
    import { onMount } from "svelte";
    import {
        parseISO,
        format,
        differenceInDays,
        addDays,
        isSameDay,
        startOfMonth,
        max,
        min,
        endOfMonth,
        addWeeks, subWeeks, addYears, addMonths, subYears, subMonths, formatDate
    } from "date-fns";
    import { selectedStartDate, selectedEndDate, oldestDate, newestDate, newestFetchedDate, oldestFetchedDate } from "../store";
    import { get } from "svelte/store";

    let hasMounted = false;
    let monthTicks: { date: Date; position: number, isJanuary?: boolean }[] = []; // Ticks for months
    let monthLabels: { date: Date; position: number; label: string; clickable: boolean }[] = []; // Labels for months
    let dayTicks: { date: Date; position: number; type?: "day" | "week" }[] = []; // Ticks for days or weeks
    let yearLabels: { date: Date; position: number }[] = []; // Labels for years
    let leftHandlePosition = 0;
    let rightHandlePosition = 100;
    let tempLeftHandlePosition = 0;
    let tempRightHandlePosition = 100;
    let displayStartDate: Date;
    let displayEndDate: Date;
    const minDateGap = 1; // Minimum gap between dates in days
    let rangeInDays: number;
    const minimumPosition = 5; // 5% padding from the left


    async function fetchTimestamps() {
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/timestamps`);
            if (!response.ok) throw new Error("Failed to fetch timestamps");

            const data = await response.json();
            const fetchedOldestDate = parseISO(data.oldestTimestamp);
            const fetchedNewestDate = parseISO(data.newestTimestamp);

            if (!$oldestFetchedDate || get(oldestFetchedDate).getTime() !== fetchedOldestDate.getTime()) {
                oldestFetchedDate.set(fetchedOldestDate);
                oldestDate.set(fetchedOldestDate);
                selectedStartDate.set(fetchedOldestDate);
                displayStartDate = fetchedOldestDate;

            }


            if (!$newestFetchedDate || get(newestFetchedDate).getTime() !== fetchedNewestDate.getTime()) {
                newestFetchedDate.set(fetchedNewestDate);
                newestDate.set(fetchedNewestDate);
                selectedEndDate.set(fetchedNewestDate);
                displayStartDate = fetchedOldestDate;

            }

            if ($oldestDate > $newestDate) {
                oldestDate.set(fetchedOldestDate);
                newestDate.set(fetchedNewestDate);
                selectedStartDate.set(fetchedOldestDate);
                selectedEndDate.set(fetchedNewestDate);
            }

            generateTicksAndLabels()

            if (!hasMounted) {
                displayStartDate = fetchedOldestDate;
                displayEndDate = fetchedNewestDate;
                selectedStartDate.set(displayStartDate);
                selectedEndDate.set(displayEndDate);
                updateHandlePositions();
                hasMounted = true;
            } else {
                displayStartDate = $selectedStartDate;
                displayEndDate = $selectedEndDate;
                updateHandlePositions();

            }
        } catch (error) {
            console.log("Error fetching timestamps, entries must be associated with atlest 2 different dates for timeframe selector to work:", error);
            oldestDate.set(new Date());
            newestDate.set(new Date());
        }
    }

    function generateTicksAndLabels() {
        monthTicks = [];
        monthLabels = [];
        dayTicks = [];
        yearLabels = [];
        rangeInDays = differenceInDays(get(newestDate), get(oldestDate));

        if (rangeInDays < 180) {
            generateDayTicks();
            generateMonthTicks();
            generateMonthLabels("MMM", true);
        } else if (rangeInDays >= 180 && rangeInDays < 500) {
            generateMonthTicks();
            generateMonthLabels("MMM", true);
        } else if (rangeInDays >= 500 && rangeInDays < 3 * 365) {
            generateMonthTicks();
            generateMonthLabels("M", false);
        } else if (rangeInDays >= 3 * 365 && rangeInDays < 7 * 365) {
            generateAlternateMonthTicks();
            generateAlternateMonthLabels();
        }
        else {
            generateJanuaryTicks();
        }
            generateYearLabels();

    }

    function generateDayTicks() {
        let current = new Date(get(oldestDate));
        while (current < get(newestDate)) {
            dayTicks.push({
                date: new Date(current),
                position: dateToPosition(new Date(current)),
            });
            current.setDate(current.getDate() + 1);
        }
    }

    function generateMonthTicks() {
        let current = new Date(get(oldestDate));
        monthTicks = [];
        while (current <= get(newestDate)) {
            const isJanuary = current.getMonth() === 0; // Check if it's January
            monthTicks.push({
                date: new Date(current),
                position: dateToPosition(new Date(current)),
                isJanuary,
            });
            current.setMonth(current.getMonth() + 1);
            current.setDate(1);
        }
    }


    function generateMonthLabels(format: string, clickable: boolean) {
        let current = new Date(get(oldestDate));
        while (current <= get(newestDate)) {
            const nextMonth = new Date(current);
            nextMonth.setMonth(nextMonth.getMonth() + 1);
            nextMonth.setDate(1);

            const midMonthDate = new Date(
                current.getTime() +
                (Math.min(nextMonth.getTime(), get(newestDate).getTime()) - current.getTime()) / 2
            );

            monthLabels.push({
                date: midMonthDate,
                position: dateToPosition(midMonthDate),
                label: format,
                clickable: true,
            });

            current.setMonth(current.getMonth() + 1);
            current.setDate(1);
        }
    }

    function generateAlternateMonthTicks() {
        let current = new Date(get(oldestDate));
        monthTicks = [];
        while (current <= get(newestDate)) {
            const month = current.getMonth(); // Get the month (0 = January, 1 = February, ...)
            const isJanuary = month === 0; // Check if it's January
            if ([0, 2, 4, 6, 8, 10].includes(month)) { // Check if the month is Jan, Mar, May, etc.
                monthTicks.push({
                    date: new Date(current),
                    position: dateToPosition(new Date(current)),
                    isJanuary,
                });
            }
            current.setMonth(current.getMonth() + 1);
            current.setDate(1);
        }
    }
    function generateAlternateMonthLabels() {
        let current = new Date(get(oldestDate));
        monthLabels = [];
        while (current <= get(newestDate)) {
            const month = current.getMonth();
            if ([0, 2, 4, 6, 8, 10].includes(month)) { // Check if the month is Jan, Mar, May, etc.
                const midMonthDate = new Date(
                    current.getTime() +
                    (Math.min(addMonths(current, 2).getTime(), get(newestDate).getTime()) - current.getTime()) / 2
                );

                monthLabels.push({
                    date: midMonthDate,
                    position: dateToPosition(midMonthDate),
                    label: "M",
                    clickable: true,
                });
            }
            current.setMonth(current.getMonth() + 1);
            current.setDate(1);
        }
    }
    function generateJanuaryTicks() {
        let current = new Date(get(oldestDate));
        monthTicks = [];
        while (current <= get(newestDate)) {
            monthTicks.push({
                date: new Date(current),
                position: dateToPosition(new Date(current)),
                isJanuary: true,
            });
            current.setFullYear(current.getFullYear() + 1);
            current.setMonth(0);
            current.setDate(1);
        }
    }

    function generateYearLabels() {
        yearLabels = []; // Reset year labels
        const rangeStart = new Date(get(oldestDate));
        const rangeEnd = new Date(get(newestDate));
        const startYear = rangeStart.getFullYear();
        const endYear = rangeEnd.getFullYear();

        for (let year = startYear; year <= endYear; year++) {
            const yearStart = new Date(year, 0, 1);
            const yearEnd = new Date(year + 1, 0, 1);

            // Clamp to the visible range
            const visibleStart = max([yearStart, rangeStart]);
            const visibleEnd = min([yearEnd, rangeEnd]);

            // Calculate the midpoint of the visible portion of the year
            const midYearDate = new Date(
                visibleStart.getTime() +
                (visibleEnd.getTime() - visibleStart.getTime()) / 2
            );

            yearLabels.push({
                date: midYearDate,
                position: dateToPosition(midYearDate),
            });
        }
    }


    function dateToPosition(date: Date) {
        const totalRange = get(newestDate).getTime() - get(oldestDate).getTime();
        const dateOffset = date.getTime() - get(oldestDate).getTime();
        return (dateOffset / totalRange) * 100;
    }

    function positionToNearestDay(position: number) {
        const totalDays = differenceInDays(get(newestDate), get(oldestDate));
        const dayOffset = Math.round((position / 100) * totalDays);
        return addDays(get(oldestDate), dayOffset);
    }

    function updateHandlePositions() {
        if ($selectedStartDate && $selectedEndDate) {
            leftHandlePosition = dateToPosition($selectedStartDate);
            rightHandlePosition = dateToPosition($selectedEndDate);
            tempLeftHandlePosition = leftHandlePosition;
            tempRightHandlePosition = rightHandlePosition;
        }
    }

    function handleScroll(event: WheelEvent) {
        const slider = event.currentTarget as HTMLElement;
        const sliderRect = slider.getBoundingClientRect();
        const mouseX = event.clientX - sliderRect.left;


        let newOldestDate = $oldestDate;
        let newNewestDate = $newestDate;

        // Determine the increment or decrement step based on the range
        const rangeInDays = differenceInDays($newestDate, $oldestDate);
        let incrementStep;

        if (rangeInDays > 5 * 365) {
            incrementStep = { add: addYears, sub: subYears, value: 1 }; // Step is 1 year
        } else if (rangeInDays > 3 * 365) {
            incrementStep = { add: addMonths, sub: subMonths, value: 6 }; // Step is 6 months
        } else if (rangeInDays > 365) {
            incrementStep = { add: addMonths, sub: subMonths, value: 2 }; // Step is 1 month
        } else if (rangeInDays > 180) {
            incrementStep = { add: addWeeks, sub: subWeeks, value: 2 }; // Step is 2 weeks
        } else {
            incrementStep = { add: addWeeks, sub: subWeeks, value: 1 }; // Step is 1 week
        }

        if (mouseX > (2 * sliderRect.width) / 3) {
            // Hovering over the left third of the slider (adjust oldest date only)
            if (event.deltaY < 0) {
                // Zoom in (increment oldest date)
                if (rangeInDays > 14) {
                    newOldestDate = incrementStep.add($oldestDate, incrementStep.value);
                    if ($selectedStartDate < newOldestDate) {
                        selectedStartDate.set(newOldestDate);
                    }
                }
            } else {
                // Zoom out (decrement oldest date)
                const tempOldest = incrementStep.sub($oldestDate, incrementStep.value);
                newOldestDate = tempOldest < $oldestFetchedDate ? $oldestFetchedDate : tempOldest;
            }
        } else if (mouseX < sliderRect.width / 3) {
            // Hovering over the right third of the slider (adjust newest date only)
            if (event.deltaY < 0) {
                // Zoom in (decrement newest date)
                if (rangeInDays > 14) {
                    newNewestDate = incrementStep.sub($newestDate, incrementStep.value);
                    if ($selectedEndDate > newNewestDate) {
                        selectedEndDate.set(newNewestDate);
                    }
                }
            } else {
                // Zoom out (increment newest date)
                const tempNewest = incrementStep.add($newestDate, incrementStep.value);
                newNewestDate = tempNewest > $newestFetchedDate ? $newestFetchedDate : tempNewest;
            }
        } else {
            // Hovering over the middle of the slider (adjust both dates)
            if (event.deltaY < 0) {
                // Zoom in (increment oldest and decrement newest)
                if (rangeInDays > 14) {
                    newOldestDate = incrementStep.add($oldestDate, incrementStep.value);
                    newNewestDate = incrementStep.sub($newestDate, incrementStep.value);
                    if ($selectedStartDate < newOldestDate) {
                        selectedStartDate.set(newOldestDate);
                    }
                    if ($selectedEndDate > newNewestDate) {
                        selectedEndDate.set(newNewestDate);
                    }
                }
            } else {
                // Zoom out (decrement oldest and increment newest)
                const tempOldest = incrementStep.sub($oldestDate, incrementStep.value);
                newOldestDate = tempOldest < $oldestFetchedDate ? $oldestFetchedDate : tempOldest;

                const tempNewest = incrementStep.add($newestDate, incrementStep.value);
                newNewestDate = tempNewest > $newestFetchedDate ? $newestFetchedDate : tempNewest;
            }
        }

        // Only update dates if they have changed
        if (newOldestDate.getTime() !== $oldestDate.getTime()) {
            oldestDate.update(() => newOldestDate);
        }
        if (newNewestDate.getTime() !== $newestDate.getTime()) {
            newestDate.update(() => newNewestDate);
        }

        displayStartDate = $oldestDate;
        displayEndDate = $newestDate;
        selectedStartDate.set(displayStartDate);
        selectedEndDate.set(displayEndDate);
        updateHandlePositions();
       // console.log($oldestDate, $newestDate);

        generateTicksAndLabels();
    }



    function startDrag(e: MouseEvent, handle: string) {
        const slider = e.target!.parentElement as HTMLElement;

        function onMouseMove(e: MouseEvent) {
            if (!$oldestDate || !$newestDate) return;

            const sliderRect = slider.getBoundingClientRect();
            const offsetX = e.clientX - sliderRect.left;
            const percentage = Math.max(0, Math.min((offsetX / sliderRect.width) * 100, 100));

            const nearestDate = positionToNearestDay(percentage);

            if (handle === "left") {
                const rightDate = positionToNearestDay(tempRightHandlePosition);
                if (differenceInDays(rightDate, nearestDate) >= minDateGap) {
                    displayStartDate = nearestDate;
                    leftHandlePosition = dateToPosition(displayStartDate);
                    tempLeftHandlePosition = leftHandlePosition;
                }
            } else if (handle === "right") {
                const leftDate = positionToNearestDay(tempLeftHandlePosition);
                const newestDatePosition = dateToPosition(get(newestDate));

                if (
                    isSameDay(nearestDate, get(newestDate)) ||
                    (differenceInDays(nearestDate, leftDate) >= minDateGap && percentage >= 99.9)
                ) {
                    displayEndDate = get(newestDate);
                    rightHandlePosition = 100;
                    tempRightHandlePosition = rightHandlePosition;
                } else if (differenceInDays(nearestDate, leftDate) >= minDateGap) {
                    displayEndDate = nearestDate;
                    rightHandlePosition = dateToPosition(displayEndDate);
                    tempRightHandlePosition = rightHandlePosition;
                }
            }
        }



        function onMouseUp() {
            selectedStartDate.set(displayStartDate);
            selectedEndDate.set(displayEndDate);
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("mouseup", onMouseUp);
        }

        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("mouseup", onMouseUp);
    }
    function handleMonthLabelClick(date: Date) {
        // Calculate the start and end of the clicked month
        const monthStart = startOfMonth(date);
        const monthEnd = endOfMonth(date);


        // Clamp further to the fetched oldest and newest dates (maximum range)
        const adjustedStart = max([monthStart, get(oldestFetchedDate)]);
        const adjustedEnd = min([monthEnd, get(newestFetchedDate)]);

        // Toggle selection: if the currently selected range matches this month, reset to full zoomed range
        if (
            displayStartDate?.getTime() === adjustedStart.getTime() &&
            displayEndDate?.getTime() === adjustedEnd.getTime()
        ) {
            oldestDate.set(get(oldestFetchedDate));
            newestDate.set(get(newestFetchedDate));
            displayStartDate = get(oldestDate);
            displayEndDate = get(newestDate);
        } else {
            // Select the clamped month range
            displayStartDate = adjustedStart;
            displayEndDate = adjustedEnd;
            oldestDate.set(adjustedStart);
            newestDate.set(adjustedEnd);
        }

        // Update selected range and handles
        generateTicksAndLabels()
        selectedStartDate.set(displayStartDate);
        selectedEndDate.set(displayEndDate);
        updateHandlePositions();
    }



    function handleYearLabelClick(date: Date) {
        // Calculate the start and end of the clicked year
        const yearStart = new Date(date.getFullYear(), 0, 1); // January 1st
        const yearEnd = new Date(date.getFullYear(), 11, 31); // December 31st

        // Clamp further to the fetched oldest and newest dates (maximum range)
        const adjustedStart = max([yearStart, get(oldestFetchedDate)]);
        const adjustedEnd = min([yearEnd, get(newestFetchedDate)]);

        // Toggle selection: if the currently selected range matches this year, reset to the full zoomed-in range
        if (
            displayStartDate?.getTime() === adjustedStart.getTime() &&
            displayEndDate?.getTime() === adjustedEnd.getTime()
        ) {
            // Reset to the full zoomed-in range
            oldestDate.set(get(oldestFetchedDate));
            newestDate.set(get(newestFetchedDate));
            displayStartDate = get(oldestDate);
            displayEndDate = get(newestDate);
        } else {
            // Select the clamped year range
            displayStartDate = adjustedStart;
            displayEndDate = adjustedEnd;
            oldestDate.set(adjustedStart);
            newestDate.set(adjustedEnd);
        }

        // Update selected range and handles
        generateTicksAndLabels();
        selectedStartDate.set(displayStartDate);
        selectedEndDate.set(displayEndDate);
        updateHandlePositions();
    }



    onMount(() => {
        window.addEventListener("fetchTimestamps", fetchTimestamps);

        fetchTimestamps();

        return () => {
            window.removeEventListener("fetchTimestamps", fetchTimestamps);
        };
    });
</script>

<style>
    .timeline-container {
        scale: 95%;
        position: relative;
        top: 8%;
        width: 100%;
        height: 50px;
        background-color: #545454;
        border-radius: 5px;
    }
    .week-tick {
        position: absolute;
        bottom: 0;
        width: 1px;
        height: 18px; /* Slightly longer than day ticks */
        background-color: rgba(255, 255, 255, 0.7); /* Less faint than day ticks */
    }

    .month-tick {
        position: absolute;
        bottom: 0;
        width: 2px;
        height: 20px;
        background-color: #FFFFFF;
    }
    .january-tick {
        height: 30px; /* Longer height for January ticks */
    }

    .day-tick {
        position: absolute;
        bottom: 0;
        width: 1px;
        height: 15px;
        background-color: rgba(255, 255, 255, 0.5);
    }

    .month-label {
        position: absolute;
        bottom: -25px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);
        transform: translateX(-50%);
        white-space: nowrap;
    }
    .month-label.clickable {
        cursor: pointer;
    }

    .year-label {
        position: absolute;
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.5);
        transform: translateX(-50%);
        white-space: nowrap;
        cursor: pointer; /* Add pointer to indicate it's clickable */

    }


    .timeline-range {
        position: absolute;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.3);
        border: 1px solid #FFFFFF;
        border-radius: 5px;
    }

    .handle {
        position: absolute;
        top: -5px;
        width: 12px;
        height: 130%;
        background-color: #ffffff;
        border-radius: 5px;
        cursor: pointer;

    }

    .range {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
    }
    .day-label {
        position: absolute;
        bottom: 15px; /* Position above the day-tick */
        font-size: 12px;
        color: rgba(255, 255, 255, 0.7); /* Adjust color for visibility */
        transform: translateX(-50%);
        white-space: nowrap;
    }


</style>

<div class="timeline-container" on:wheel={handleScroll}>
    {#if $oldestDate && $newestDate && !isSameDay($oldestDate, $newestDate)}
        <div class="timeline-range" style="left: {leftHandlePosition}%; width: {rightHandlePosition - leftHandlePosition}%;"></div>

        <div
                class="handle"
                style="left: {leftHandlePosition}%;"
                on:mousedown={(e) => startDrag(e, "left")}
                title={displayStartDate ? format(displayStartDate, "yyyy-MM-dd") : ""}>
        </div>

        <div
                class="handle"
                style="left: {rightHandlePosition}%;"
                on:mousedown={(e) => startDrag(e, "right")}
                title={displayEndDate ? format(displayEndDate, "yyyy-MM-dd") : ""}>
        </div>

        {#if rangeInDays < 32}
            {#each dayTicks as tick}
                {#if tick.position > 1 && tick.date.getDate() !== 1}
                    <div class="day-tick" style="left: {tick.position}%;"></div>
                    <div
                            class="day-label"
                            style="left: {tick.position}%;"
                    >
                        {format(tick.date, "d")} <!-- Display day number -->
                    </div>
                {/if}
            {/each}
        {:else}
            {#each dayTicks as tick}
                {#if tick.position > 1}
                    <div class="day-tick" style="left: {tick.position}%;"></div>
                {/if}
            {/each}
        {/if}

        {#each monthTicks as tick}
            {#if tick.position > 1}
                <div
                        class="month-tick {tick.isJanuary ? 'january-tick' : ''}"
                        style="left: {tick.position}%;"
                ></div>
            {/if}
        {/each}

        {#each monthLabels as label}
            {#if label.position > minimumPosition}
                <div
                        class="month-label {label.clickable ? 'clickable' : 'non-clickable'}"
                        style="left: {label.position}%;"
                        class:clickable={label.clickable}
                        on:click={label.clickable ? () => handleMonthLabelClick(label.date) : null}
                >
                    {format(label.date, label.label)}
                </div>
            {/if}
        {/each}

        {#each yearLabels as label}
            {#if label.position > minimumPosition}
                <div
                        class="year-label"
                        style="left: {label.position}%;"
                        on:click={() => handleYearLabelClick(label.date)}
                >
                    {rangeInDays > 16 * 365
                        ? format(label.date, "yy")
                        : format(label.date, "yyyy")
                    }
                </div>
            {/if}
        {/each}
    {/if}
</div>


{#if displayStartDate && displayEndDate && !isSameDay(displayStartDate, displayEndDate)}
    <p class="range">
        {format(displayStartDate, "yyyy MMM d")} - {format(displayEndDate, "yyyy MMM d")}
    </p>
{:else}
    {#if $oldestDate}
        <p class="range">{format($oldestDate, "yyyy MMM d")}</p>
    {:else}
        <p class="range"></p>
    {/if}
{/if}
